import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import { ListState, Select_TaskTyp, Select_ansh, Select_userNames, SelectyedidymState } from "../models/user-model";


const listSlice = createSlice({
    name: "Detils",
    initialState: {} as ListState,
    reducers: {
        setlistSlice: (state, action: PayloadAction<ListState>) => {
            return action.payload
        },
        setTaskTyp: (state, action: PayloadAction<Select_TaskTyp[]>) => {
            state.select_TaskTyp = action.payload
        },
        setUserNames: (state, action: PayloadAction<Select_userNames[]>) => {
            state.select_userNames = action.payload
        },
        setAnsh: (state, action: PayloadAction<Select_ansh[]>) => {
            state.select_ansh = action.payload
        },
        setSelectyedidym: (state, action: PayloadAction<SelectyedidymState[]>) => {
            state.Selectyedidym = action.payload
        },
        removeList: (state, action) => {
            state = {} as ListState
        },
        
    },

})

export const userSelector = listSlice.selectSlice

export const {setlistSlice,setTaskTyp,setUserNames,setAnsh,setSelectyedidym,removeList} = listSlice.actions

export default listSlice.reducer;