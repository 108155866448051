import {PayloadAction, createSelector, createSlice} from "@reduxjs/toolkit";
import UserState from "../models/user-model";
import { start } from "repl";

const KEY_DETILS = "todo_detils";
const initDetils = localStorage[KEY_DETILS] ? JSON.parse(localStorage[KEY_DETILS]) : {
    userDetils: []
  }


const userSlice = createSlice({
    name: "Detils",
    initialState: initDetils,
    reducers: {
        setUserDetils: (state, action: PayloadAction<UserState>) => {
            
            saveToLocal(action.payload);
            return action.payload
        },
        updateName: (state, action: PayloadAction<string>) => {
            state.NameUser = action.payload;
        },
        removeUser: (state, action) => {
            state = {} as UserState
            localStorage.removeItem(KEY_DETILS)
        },
    
    },
    
})

//export const getUserId = createSelector((start) => start)


const saveToLocal = (user: UserState) => {
    localStorage.setItem(KEY_DETILS, JSON.stringify(user))
}
export const userSelector = userSlice.selectSlice

export const {setUserDetils, updateName,removeUser} = userSlice.actions;

export default userSlice.reducer;