import { useState } from 'react';
import { SideMenuState } from './sideModel';
import { Outlet, useNavigate } from "react-router-dom";


export function SideNavMenu(props: any) {
    const { sideLink, isNavOpen, setIsNavOpen } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const nav = useNavigate();


    //תפריט משני
    function NavSide(props: any) {
        const { sideLinks, isNavOpen, setIsNavOpen } = props;
        return (
            <li onClick={() => {
                nav(sideLinks.path);
                document.title = sideLinks.text;
                setIsNavOpen(false);
            }}>
                <a className={`linksMenu shadow ${!isNavOpen && 'linksClosed'}`}>
                    {isNavOpen && sideLinks.text}
                    <span className="icon" > {sideLinks.icon} </span>
                </a>
            </li>
        )
    }


    return (
        <>
            <li onClick={() => {
                setIsMenuOpen(!isMenuOpen);
            }}>
                <a className={`shadow ${!isNavOpen && 'linksClosed '} ${isMenuOpen ? 'linksNav' : 'links'}`}>
                    {isNavOpen && sideLink.text}
                    <span className="icon" > {sideLink.icon} </span>
                </a>
                {isMenuOpen &&
                    <div className="linksMenuNav">
                        {sideLink.subNav.map((item: SideMenuState) =>
                            <NavSide key={item.id} sideLinks={item} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
                        )}
                    </div>
                }
            </li>
        </>
    )
}
