import React, { useEffect } from 'react';


import { RouterProvider } from 'react-router-dom';
import { router } from './appRoutes';
import './App.css';
import { handleVersion } from './pages/sistem/cookie';

function App() {


  useEffect(() => {
    handleVersion(); // קריאה לפונקציה שמטפלת בקוקיז ובגרסאות
  }, []);


  return (
   <RouterProvider router={router} />
  );
}

export default App;
