import axios from 'axios';


// יצירת אינסטנס של axios
const api = axios.create({
  baseURL: 'https://your-api-url.com',
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  }
});

// הגדרת interceptor לתפיסת תגובות
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // הפניית המשתמש לדף ההתחברות
      console.log('Unauthorized');
      
        window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;