import { configureStore } from '@reduxjs/toolkit';
import userSlice, { removeUser } from './userState';
import listSlice, { removeList } from './listState';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const store = configureStore({
  reducer: {
    userSlice,
    listSlice,


  }
})

export type RootState = ReturnType<typeof store.getState>




export const RemovStore = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  dispatch(removeUser({}));
  dispatch(removeList({}));
  return nav('/login');

}
