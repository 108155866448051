import React, { useEffect, useState } from "react"
import { PiCaretDoubleRight } from "react-icons/pi";
// import 'bootstrap/dist/css/bootstrap.min.css'
import './cardsList.css'


import { orderBy, sortBy } from "lodash";
import useScrollPages from "../../hooks/useScrollPages";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserState from "../../models/user-model";
import { RemovStore } from "../../redux/appStore";
import { Card_Lite } from "../../models/card_model";
import { useDispatch } from "react-redux";
import apiAxios from "../../redux/apiAxios";





export default function CardsList() {
    const userSlice: UserState = useSelector((state: any) => state.userSlice);
    const listSlice = useSelector((state: any) => state.listSlice);
    const [ar, setAr] = useState<Card_Lite[]>([]);
    const [arList, setArList] = useState<Card_Lite[]>([]);
    const [selectYear] = useState(listSlice.year_Magbis);
    const nav = useNavigate();
    const { isEnd, setIsEnd } = useScrollPages(true);
    let findBox = 1
    const [offset, setOffset] = useState(0);
    const [limit, setlimit] = useState(300);


    useEffect(() => {
        setArList([...ar]);
    }, [ar])

    //גלילה
    useEffect(() => {
        if (isEnd) {
            setOffset(offset + limit)
            doApi();
            // alert(offset)
            // setIsEnd(false)
        }
    }, [isEnd])




    const doApi = async () => {
        try {
            const url = process.env.REACT_APP_API_URL! + `cards/ansh?offset=${offset}${limit && `&limit=${limit}`}`
            const data = (await apiAxios.get(url, { headers: { 'x-api-key': userSlice.token } })).data

            const imp: any = data.map((item: any) => ({ ...item, fulname: item.FirstName + ' ' + item.FamilyName + ' ' + item.Address + ' ' + item.Region }))
            setAr([...ar, ...imp]);
            setArList([...ar]);

            // console.log(data.length, isEnd, offset)

            if (data.length > (limit - 1)) {
                setIsEnd(false)
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                RemovStore()
            }
        }

    }


    const C2clink = async (tel: string) => {
        const url = process.env.REACT_APP_API_URL! + `tools/c2clink/coll`
        const data = (await apiAxios.post(url, { tel }, { headers: { 'x-api-key': userSlice.token } })).data
    }



    //כלאסים לשדות בטבלה
    const clNataKe = "h-25 fs-6 fw-lighter p-0 mhp-10"
    const clNata = "h-25 fs-6 p-0"
    const clNataTel = "p-0 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"


    const upFilterAr = (e: any) => {
        if (findBox) {
            setlimit(9999999999999999);
            setIsEnd(true);
            findBox = 0

        }

        const fl: string = e

        const fe = fl.split(" ");
        const filter = ar.filter(row => fe.every(keyWord => row.fulname.includes(keyWord))
        )

        { fl ? setArList(filter) : setArList(ar) }

    }


    return (
        <div >
            <div className="row sticky-top bg-body">
                <h2 className="col">אנש</h2>
                <input className="col btn border mx-40 " onInput={(e: any) => upFilterAr(e.target.value)} placeholder="חפש..."  ></input>

            </div>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th>#</th>
                        <th onClick={() => setArList(sortBy(arList, 'idcards'))} onDoubleClick={() => setArList(orderBy(arList, 'idcards', 'desc'))} >כרטיס</th>
                        <th onClick={() => setArList(sortBy(arList, 'FirstName'))} onDoubleClick={() => setArList(orderBy(arList, 'FirstName', 'desc'))} >שם</th>
                        <th onClick={() => setArList(sortBy(arList, 'FamilyName'))} onDoubleClick={() => setArList(orderBy(arList, 'FamilyName', 'desc'))} >משפחה</th>
                        <th onClick={() => setArList(sortBy(arList, 'FatherName'))} onDoubleClick={() => setArList(orderBy(arList, 'FatherName', 'desc'))} >שם אב</th>
                        <th onClick={() => setArList(sortBy(arList, 'chsnoy'))} onDoubleClick={() => setArList(orderBy(arList, 'chsnoy', 'desc'))} >חותנו</th>
                        <th onClick={() => setArList(sortBy(arList, 'Address'))} onDoubleClick={() => setArList(orderBy(arList, 'Address', 'desc'))} >כתובת</th>
                        <th onClick={() => setArList(sortBy(arList, 'Building'))} onDoubleClick={() => setArList(orderBy(arList, 'Building', 'desc'))} >מספר</th>
                        <th onClick={() => setArList(sortBy(arList, 'Region'))} onDoubleClick={() => setArList(orderBy(arList, 'Region', 'desc'))} >שכונה</th>
                        <th onClick={() => setArList(sortBy(arList, 'City'))} onDoubleClick={() => setArList(orderBy(arList, 'City', 'desc'))} >עיר</th>
                        <th onClick={() => setArList(sortBy(arList, 'Telephone'))} onDoubleClick={() => setArList(orderBy(arList, 'Telephone', 'desc'))} >טלפון</th>
                        <th onClick={() => setArList(sortBy(arList, 'Mobile1'))} onDoubleClick={() => setArList(orderBy(arList, 'Mobile1', 'desc'))} >פלפון</th>
                        <th onClick={() => setArList(sortBy(arList, 'Mobile2'))} onDoubleClick={() => setArList(orderBy(arList, 'Mobile2', 'desc'))} >פלפון בית</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>

                    {arList.map((item, i) => {
                        return (
                            <tr key={item.idcards} >
                                <td ><button onClick={() => { nav(`/magbis?idcard=${item.idcards}&year=${selectYear}`) }} className="btn" > <PiCaretDoubleRight size={20} /> </button></td>
                                <td className={clNataKe}>{i + 1}</td>
                                <td className={clNataKe}>{item.idcards}</td>
                                <td className={clNata}>{item.FirstName}</td>
                                <td className={clNata}>{item.FamilyName}</td>
                                <td className={clNata}>{item.FatherName}</td>
                                <td className={clNata}>{item.chsnoy}</td>
                                <td className={clNata}>{item.Address}</td>
                                <td className={clNata}>{item.Building + (item.Home ? "/" + item.Home : "")}</td>
                                <td className={clNata}>{item.Region}</td>
                                <td className={clNata}>{item.City}</td>
                                <td className={clNataTel} onClick={() => C2clink(item.Telephone)} > {item.Telephone} </td>
                                <td className={clNataTel} onClick={() => C2clink(item.Mobile1)} > {item.Mobile1} </td>
                                <td className={clNataTel} onClick={() => C2clink(item.Mobile2)} > {item.Mobile2} </td>
                            </tr>
                        )
                    })}


                </tbody>
            </table>
            <footer>

            </footer>


        </div>

    )


}