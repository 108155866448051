import Modal from 'react-bootstrap/Modal';
import { IoIosSave } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";

import apiAxios from "../../../redux/apiAxios";
import { useForm } from "react-hook-form"
import {  useState } from "react";


export default function GiftAddEdit(props: any) {
  const { item, doApi, headers } = props;
  
  const [show, setShow] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //בלחיצה על buutn מופעל פונקציה זו
  const onSubForm = async (_bodyData: any) => {
    //עדכון
    if (item.idgifts) {
      const bodyUp = {
        idcard: item.idcard,
        Seats: item.Seats,
        Gift: _bodyData.Gift,
        Notes: _bodyData.Notes,
      }
      const url = process.env.REACT_APP_API_URL! + `magbis/gifts/` + item.idgifts
      const data = (apiAxios.put(url, bodyUp, { headers }))
        .then((e) => {
          doApi();
          setShow(false);
          // console.log('then : ', e.data);
        })
        .catch((e) => {
          alert(e.response.data);
          console.log('catch : ', e.response.data);
        });

    } else {
      //חדש
      const bodyUp = {
        idcard: item.idcard,
        Seats: item.Seats,
        Gift: _bodyData.Gift,
        Notes: _bodyData.Notes,
      }
      const url = process.env.REACT_APP_API_URL! + `magbis/gifts/`
      const data = (apiAxios.post(url, bodyUp, { headers }))
        .then((e) => {
          doApi();
          setShow(false);
          // console.log('then : ', e.data);
        })
        .catch((e) => {
          alert(e.response.data);
          // console.log('catch : ', e.response.data);
        });
    }

  }

  const onDelete = ()=>{
    const url = process.env.REACT_APP_API_URL! + `magbis/gifts/` + item.idgifts
    const data = (apiAxios.delete(url, { headers }))
    .then((e) => {
      doApi();
      setShow(false);
      console.log('then : ', e.data);
    })
    .catch((e) => {
      alert(e.response.data);
      // console.log('catch : ', e.response.data);
    });
  }




  return (
    <>

      <button onClick={handleShow} className="btn p-0 mx-2 fs-6" title='עריכת כרטיס'>
        {props.icon}
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title >עריכת מתנה <span className='ps-5'></span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form className="" onSubmit={handleSubmit(onSubForm)} id="id_form" key={item.idgifts}>
            <label>מתנה</label>
            <input defaultValue={item.Gift} {...register("Gift", { required: true, minLength: 2 })} className="form-control" type="text" />
            {errors.Gift && <div className="text-danger">* הקלד שם חוקי</div>}
            <label>פרטים</label>
            <input defaultValue={item.Notes} {...register("Notes", { required: false, minLength: 2 })} className="form-control" type="text" />
            {errors.Notes && <div className="text-danger">* הקלד הערה חוקית</div>}
            <div className="d-flex justify-content-center mt-2">
                <button type='submit' className="btn me-2 ms-2 border" style={{width: '100px', height: '50px',color:'blue'}}>
                <IoIosSave size={32} />
              </button>
              <p onClick={onDelete} className="btn ms-2 border" style={{width: '100px', height: '50px',color:'red'}}>
                <RiDeleteBin6Line size={32} />
              </p>
            </div>
          </form>

        </Modal.Body>
        {/* <Modal.Footer>
        </Modal.Footer> */}
      </Modal>
    </>

  )
}

