import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function AppWeather() {
  let [info, setInfo] = useState({});
  useEffect(() => {
    doApi("jerusalem")
  }, []);

  const doApi = async (_town) => {
    let url = `http://api.openweathermap.org/data/2.5/weather?q=${_town}&APPID=d18f94625614148827ec3c7ef99253fc&units=metric`;
    let resp = await axios.get(url);
    setInfo(resp.data);
  }


  return (
    <React.Fragment>
      {info.name && <div className='container text-center mt-3 '>
        <h2 className='display-6 '>מזג אוויר {info.name}</h2>
        {<img src={`http://openweathermap.org/img/w/${info.weather[0].icon}.png`} alt="weather icon" width="70" />}
        <h2 className='display-5'>{info.weather[0].description}</h2>
        <h3>מצב: {info.weather[0].main}</h3>
        <h3>טמרטורה: {info.main.temp} C</h3>
        <h3>רוח: {info.wind.speed} ק"מ</h3>
        <h3>לחות: {info.main.humidity} </h3>
      </div>}
    </React.Fragment>
  )
}
