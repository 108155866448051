import React, { useState } from 'react'
import TasksFilter from './tasksFilter'
import TasksBox from './tasksBox'
import { useSelector } from 'react-redux'
import axios from 'axios'
import UserState from '../../models/user-model'
import { useDispatch } from 'react-redux'
import { removeUser } from '../../redux/userState'
import { removeList } from '../../redux/listState'
import { useNavigate } from 'react-router-dom'
import TasksState from './tasks'

export default function TasksApp() {
    const userSlice: UserState = useSelector((state: any) => state.userSlice);

    const [arList, setArList] = useState<TasksState[]>([]);
    const baseUrl = process.env.REACT_APP_API_URL
    const dispatch = useDispatch();
    const nav = useNavigate();

    const doApi = async (filter: string) => {
        try {
            const url: string = baseUrl + "tasks/";
            const resp = await axios({
                url,
                method: 'PUT',
                data: {
                    filter: filter,
                },
                headers: {
                    'x-api-key': userSlice.token
                },
            });
            setArList(await resp.data);
        } catch (error: any) {
            if (error.response.status === 401) {

                dispatch(removeUser({}));
                dispatch(removeList({}));
                return nav('/login');
            }
        }
    }



    return (
        <div>

            <div className='sticky-top shadow-sm bg-body rounded ow-cols-3'>
                <TasksFilter doApi={doApi} />
            </div>
            <div className='d-flex flex-wrap justify-content-around '>

                {arList.map((item, i) => {
                    return (
                        <TasksBox arList={item} />

                    )
                })}

            </div>


        </div>
    )
}
