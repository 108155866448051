import { useEffect, useState } from "react"
import { Data_State } from "./magbis_model"
import apiAxios from "../../redux/apiAxios";
import UpdateAvtuce from "./updateAvtuce";
import { BiSolidEdit } from "react-icons/bi";



export default function MagbisHeader(props: any) {
    const { idCard, year, headers } = props
    const [arList, setArList] = useState<any>([]);



    useEffect(() => {
        doApi();
    }, [idCard, year])

    //ייבוא פרטים לפי כרטיס ושנה
    const doApi = async () => {
setArList([]);
        if (idCard === undefined || year === undefined) { return [] }
        try {
            const url: string = process.env.REACT_APP_API_URL! + "magbis/header/" + idCard + "/" + year + "/";
            const data: Data_State = (await apiAxios.get(url, { headers })).data[0]
            // console.log(await data);

            setArList(await data);
            console.log(arList);


        } catch (error: any) {
            if (error.response.status === 401) {

            }
        }
    }



    return (




        <div className="d-flex flex-wrap justify-content-around">
           {!arList.Realized && <UpdateAvtuce item={arList} doApi={doApi} headers={headers} icon={<BiSolidEdit size={30} />} />}
            <form className="form-floating flex-fill p-1">
                <input type="text" className="form-control text-center fs-5" value={arList.Price > 0 ? arList.Price + " " + arList.NameCurrencyH + " - " + arList.Paymentmethod : 'אין התחייבות'} />
                <label >סך ההבטחה - אמצעי</label>
            </form>

            <form className="form-floating flex-fill p-1">
                <input type="resp" className="form-control text-center fs-5" value={arList.Accepted + " " + arList.NameCurrencyH} />
                <label >התקבל</label>
            </form>
            {arList.Price > 0 &&
                <>
                    <form className="form-floating flex-fill p-1 ">
                        <input type="resp" className="form-control text-center fs-5" value={arList.balance + " " + arList.NameCurrencyH} />
                        <label >יתרה לתשלום</label>
                    </form>


                    <form className="form-floating flex-fill p-1 ">
                        <input type="resp" className="form-control text-center fs-5" value={arList.ReasonMissing} />
                        <label >תואר</label>
                    </form>
                </>
            }
            <form className="form-floating flex-fill p-1 ">
                <input type="resp" className="form-control text-center fs-5" value={arList.FirstName + " " + arList.FamilyName} />
                <label >מתרים</label>
            </form>


        </div>

    )
}

