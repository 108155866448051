import React from 'react'
import { createBrowserRouter, redirect} from 'react-router-dom'
import Login from './pages/login/login';
import Page404 from './pages/errorPage/page404';
import Home from "./pages/home/home";
import { Main } from './pages/home/sideMenu/side';
import CardsList from './pages/cards/cardsList';
import MagbisApp from './pages/magbis/magbisApp';
import TasksApp from './pages/tasks/tasksApp';
import ListMagbis from './pages/magbis/listMagbis';
import Sds from './pages/methodsPayment/sds';
import Chat from './pages/chat/chat';

export enum APP_ROUTES {
    HOME = 'home',
    MAGBIS = 'magbis'
}


export const router = createBrowserRouter([
    {
        path: 'login',
        element: <Login />,


    },
    {
        path: '/', element: <Main />,
        children: [
            {
                index: true,
                loader: async () => redirect(`/${APP_ROUTES.HOME}`),
            },
            {path: APP_ROUTES.HOME, element: <Home />},
            {path: 'ansh', element: <CardsList/>},
            {path: 'magbis', element: <MagbisApp/>},
            {path: 'ListMagbis', element: <ListMagbis/>},
            {path: 'tasks', element: <TasksApp/>},
            // {path: 'AppTodo', element: <AppTodo/>},
            {path: 'sds', element: <Sds/>},
            {path: 'chat', element: <Chat clientCode="1" />},


        ]
    },
    {path: '/*', element: <Page404 />},
])
