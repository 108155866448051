import React, { useEffect, useState } from 'react'

//מזהה גלילת מסך
export default function useScrollPages(props) {
    const [isEnd, setIsEnd] = useState(true);
    // const [findBox, setfindBox] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [])

    const onScroll = () => {
        let windowHeight = window.innerHeight // נקודת גובה המסמך
        let scrollTop = document.documentElement.scrollTop //קנודת חלק העליון
        let docHeight = document.documentElement.offsetHeight // גובה המסמך

        if (Math.ceil(windowHeight + scrollTop) === (docHeight)) {
            setIsEnd(true)
        }

    }

    // //מייבא הכל אם רוצים להשתמש בחיפוש
    // useEffect(() => {
    //     if (findBox) (
    //         setIsEnd(true)
    //     )
    // }, [findBox,isEnd])


    return { isEnd, setIsEnd }
}
