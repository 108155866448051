
import React, { useEffect, useRef, useState } from "react";
import { links, linksYedidim } from "./sideData";
import './side.css';
import { Outlet, useNavigate } from "react-router-dom";
import * as iconAi from "react-icons/ai";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/appStore";
import { useDispatch } from "react-redux";
import { removeList } from "../../../redux/listState";
import { removeUser, setUserDetils } from "../../../redux/userState";
import { Image } from "react-bootstrap";
import { SideMenuState } from "./sideModel";
import { SideNavMenu } from "./sideNavMenu";


export const Main = () => {
    const [isNavOpen, setIsNavOpen] = useState(true);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const userSelector = useSelector((state: RootState) => state.userSlice);
    const sideLinks = userSelector.role === 2 ? links : linksYedidim

    useEffect(() => {
        if (!userSelector.IdUser) {
            if (localStorage.getItem('todo_detils')) {
                dispatch(setUserDetils(JSON.parse(localStorage.getItem('todo_detils')!)))
            } else {
                nav('/login')
            }
        }
    }, [userSelector])


    const Logout = () => {
        dispatch(removeUser({}))
        dispatch(removeList({}))
        nav('/login')
    }


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600 && isNavOpen) {
                setIsNavOpen(false)
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isNavOpen])

    //תפריט ראשי
    function NavMenu(sideLink: SideMenuState) {
        if (sideLink.path === 'menu') {
            return (
                <SideNavMenu sideLink={sideLink} isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
            )
        } else {
            return (
                <li onClick={() => {
                    nav(sideLink.path);
                    document.title = sideLink.text;
                    setIsNavOpen(false);
                }}>
                    <a className={`links shadow ${!isNavOpen && 'linksClosed'}`}>
                        {isNavOpen && sideLink.text}
                        <span className="icon" > {sideLink.icon} </span>
                    </a>
                </li>
            )
        }
    }



    return (
        <div>

            <nav className={`nav ${isNavOpen ? 'nav-open' : 'nav-closed'}`} >

                {isNavOpen ?
                    <div className="Logout d-flex justify-content-between">
                        <span className="LogoutHover" onClick={Logout} ><RiLogoutCircleRLine size={35} /></span>
                        {userSelector.NameUser}
                        <span onClick={() => setIsNavOpen(false)} > <iconAi.AiOutlineMenuUnfold size={35} /> </span>
                    </div> :
                    <span className="LogoutClosed" onClick={() => setIsNavOpen(true)} > <iconAi.AiOutlineMenuFold size={35} /></span>
                }

                <div onMouseOver={() => { setIsNavOpen(true) }} className="navMenu">

                    <ul >
                        {sideLinks.map((link) => (
                            NavMenu(link)
                        ))}
                    </ul>

                </div>
                <div>
                    <h2 className={isNavOpen ? 'display-4' : 'display-7'} >MTA</h2>
                    {isNavOpen &&
                        <div className="text-center " >
                            <Image src={require('../../../img/bnyan.png')} className="bnyan" />
                        </div>}
                </div>
            </nav>
            <div className={`outlet ${!isNavOpen && 'outletClosed'}`}>
                <Outlet />
            </div>
        </div>
    );
}
