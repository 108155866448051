import Modal from 'react-bootstrap/Modal';
import { IoIosSave } from "react-icons/io";
import { MdOutlineLockPerson } from "react-icons/md";

import apiAxios from "../../redux/apiAxios";
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import UserState, { ListState } from '../../models/user-model';
import { useSelector } from 'react-redux';

export default function UpdateAvtuce(props: any) {
  const { item, doApi, headers } = props;
  const userSlice: UserState = useSelector((state: any) => state.userSlice);
  const listSlice: ListState = useSelector((state: any) => state.listSlice);

  const [show, setShow] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Active, setActive] = useState(item.Active)

  //בלחיצה על buutn מופעל פונקציה זו
  const onSubForm = async (_bodyData: any) => {
    //עדכון
    const url = process.env.REACT_APP_API_URL! + `magbis/UpdateAvuce`
    const idstudent = _bodyData.idstudent || item.idstudent;
    const data = (apiAxios.put(url, { ..._bodyData, idData: item.idData, idcard: item.idcard, Active, idstudent }, { headers }))
      .then((e) => {
        doApi();
        setShow(false);
      })
      .catch((e) => {
        alert(e.response.data);
        console.log('catch : ', e.response.data);
      });


  }

  useEffect(() => {
    setActive(item.Active)
  }, [item.Active])

  return (
    <>

      <button onClick={handleShow} className="btn p-0 mx-2 fs-6" title='עריכת הבטחה מגבית'>
        {props.icon}
      </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton  >
          <Modal.Title className='display-6 ps-5 text-muted' >עריכת הבטחה מגבית</Modal.Title>
        </Modal.Header >
        <div className='text-center p-2'>
          <span className='fw-bold fs-5'>{item.FirstName} </span>
          <span >{item.MiddleName}</span>
          <span  className='fw-bold fs-5'> {item.FamilyName}</span>

        </div>
        <Modal.Body>


          <form className="" onSubmit={handleSubmit(onSubForm)} id="id_form" key={item.idData}>


            <div className="d-flex col">
              <p onClick={() => { setActive(Active == 0 ? -1 : 0) }} className='btn' title={Active == 0 ? '  לא סופי' : ' לא סופי'} >   <span className='m-2 '>סופי  </span>
                {Active == 0 ? <BsToggleOff size={40} /> : <BsToggleOn size={40} />}
              </p>


              <div className=' m-4 text-center' hidden={userSlice.role > 0 ? false : true} >
                <p className=' m-0 fw-bold'>מתרים</p>
                <select  {...register("idstudent", { required: false })} defaultValue={item.idstudent} className=' btn p-0' >
                  <option disabled selected >תבחר מתרים בבקשה</option>
                  <option value=''>ללא מתרים</option>
                  {listSlice.Selectyedidym?.map((item, key) => (
                    <option value={item.idcards} > {item.FirstName + ' ' + item.FamilyName}</option>
                  ))}
                </select>
              </div>
            </div>


            <div className="d-flex col">
              <div className=' m-4 text-center'>
                <p className=' m-0 fw-bold '>מחיר</p>
                <input type="text" {...register("Price", { required: true })} defaultValue={item.Price} className='form-control border text-center' />
              </div>
              <div className=' m-4 text-center'>
                <p className=' m-0 fw-bold'>מטבע</p>
                <select {...register("IdCurrency", { required: false })} defaultValue={item.IdCurrency} className='form-control border text-center' >
                  <option disabled selected >תבחר מטבע בבקשה</option>
                  <option value='97' > דולר</option>
                  <option value='99' > שקל</option>
                  <option value='94' > דולר יציג</option>
                  <option value='' > ללא</option>
                </select>
              </div>
            </div>
            <div className=' m-4 text-center'>
              <p className='m-0 fw-bold'>אמצעי תשלום</p>
              <select {...register("Paymentmethod", { required: false })} defaultValue={item.Paymentmethod} className='btn border form-control' >
                <option disabled selected >תבחר אמצעי תשלום בבקשה</option>
                <option value='הוראת קבע' > הוראת קבע</option>
                <option value='כרטיס אשראי' > כרטיס אשראי</option>
                <option value='גובה' >גובה </option>
                <option value='משולב' >משולב </option>
                <option value='מילגות' >מילגות </option>
                <option value='מטה אהרן' >מטה אהרן </option>
                <option value='תת ביש' >תת ביש </option>
                <option value='' >ללא </option>
              </select>
            </div>
            <div className=' m-4 text-center'>
              <p className='m-0 fw-bold'>הערות</p>
              <textarea  {...register("Notes", { required: false })} defaultValue={item.Notes} style={{ height: '150px', width: '415px' }} className=' border p-2 rounded' />
            </div>
            {item.Realized ?
              <div className="btn mt-2  text-center form-control border "> <MdOutlineLockPerson size={32} /> הבטחה כבר טופל במשרד - אי אפשר לשנות</div>
              :
              <button type='submit' className="btn mt-2  text-center form-control border "><IoIosSave size={32} /></button>
            }
          </form>


        </Modal.Body>
        {/* <Modal.Footer>
        </Modal.Footer> */}
      </Modal>
    </>


  )
}

// Price: string,
// IdCurrency: string,
// shar: string,
// TotalPayment: string,
// TotalIL: string,
// Paymentmethod: string,
// Active: string,
// Notes: string,
// UserChange: string,
// idData: string