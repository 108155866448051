import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { RiFileExcel2Line } from 'react-icons/ri';

export default function SheetXlsx(props: any) {
  const { arList,nameFile ,sizeIcon ,className} = props
  return (
    <button className={className} onClick={async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(nameFile);
    
            // הוספת כותרות העמודות
            const columns = Object.keys(arList[0]).map(key => ({ header: key, key: key ,width: key.length*3+5}));
            worksheet.columns = columns;
    
            // הוספת הנתונים
            arList.forEach((item: any) => {
                worksheet.addRow(item);
            });
    

            // הגדרת כיוון מימין לשמאל
            worksheet.views = [{ rightToLeft: true }];
            worksheet.eachRow((row, rowNumber) => {
                row.eachCell((cell, colNumber) => {
                    cell.font = {
                      name: 'Arial',
                      family: 2,
                      size: 14,
                    };
                    cell.border = {
                      top: {style:'thin', color: {argb:'black'}},
                      left: {style:'thin', color: {argb:'black'}},
                      bottom: {style:'thin', color: {argb:'black'}},
                      right: {style:'thin', color: {argb:'black'}}
                     };
                });
            });

            // worksheet.columns.forEach(column => {
            //   column.width = 20; // You can set an initial width if needed
            // });
            
            // יצוא לאקסל
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, nameFile + '.xlsx');
        } catch (error) {
            console.error('Error generating Excel file:', error);
        }
    }}> <RiFileExcel2Line size={sizeIcon}/> </button>
  )
}
