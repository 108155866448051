import { useEffect, useState } from 'react';
import { MethodsPaymentMagbis_State } from '../magbis_model'
import apiAxios from "../../../redux/apiAxios";
import MethodsPaymentAddEdit from '../../methodsPayment/methodsPaymentAddEdit';

export default function MagbisMethodsPayment(props: any) {
    const { idCard, headers } = props
    const [arList, setArList] = useState<any>([]);

    useEffect(() => {
        doApi()
    }, [idCard])

    //ייבוא פרטים לפי כרטיס
    const doApi = async () => {
        setArList([]);
        if (idCard === undefined) { return [] }
        try {

            const url: string = process.env.REACT_APP_API_URL! + "Payment/MethodsPayment/" + idCard
            const data: MethodsPaymentMagbis_State = (await apiAxios.get(url, { headers })).data
            // console.log(await data);

            setArList(await data);
            console.log(arList);

        } catch (error: any) {
            if (error.response.status === 401) {

            }
        }
    }



    return (
        <>
            {arList ?
                <div className='d-flex flex-wrap'>
                    {/* {arList.IdPermissionsBank && <MethodsPaymentAddEdit IdPermissionsBank={arList.IdPermissionsBank} />} */}
                    <h5 className='text-center me-5'>אמצעי תשלום קבוע למגבית</h5>

                    {arList.Paymentmethod === 'הוראת קבע' ?
                        <div className='text-center'>
                            <div>
                                {arList.Paymentmethod}, בנק-{arList.BankTax}, סניף-{arList.Branch}, מס חשבון-{arList.TaxAccount}, ע"ש-{arList.NameAccount}
                            </div>
                            <div>
                                יום חיוב - "{arList.DayPayment}" בחודש,
                            </div>
                        </div>
                        :
                        <div>
                            <div>
                                <span>{arList.Paymentmethod} </span>
                                <span>{arList.MisCard4}, </span>
                            </div>
                            <div>
                                <span>יום חיוב - </span>
                                <span>{arList.DayPayment}, </span>
                            </div>
                            <div>
                                <span>ע"ש - </span>
                                <span>{arList.NameAccount} </span>
                            </div>
                        </div>
                    }

                </div>
                : <h5 className='text-center border border rounded m-4 p-4'>אין אמצעי קבוע למגבית</h5>}
        </>
    )
}
