export class getH{
    constructor(num){
        this.num = num
    }

    static MontH = (num) => {
        const mi = listMonth.filter(a => a.num === num)
           return mi[0].heb
       }
   

     static YearH = (num) => {
     const mi = listYears.filter(a => a.id === num)
        return mi[0].heb_num
    }



}




export const listMonth = [
    {
    "num": "1.0",
    "heb": "תשרי"
    },
    {
    "num": "1.1",
    "heb": "יוט תשרי"
    },
    {
    "num": "2.0",
    "heb": "חשון"
    },
    {
    "num": "3.0",
    "heb": "כסלו"
    },
    {
    "num": "4.0",
    "heb": "טבת"
    },
    {
    "num": "5.0",
    "heb": "שבט"
    },
    {
    "num": "6.0",
    "heb": "אדר"
    },
    {
    "num": "6.1",
    "heb": "אדר א'"
    },
    {
    "num": "6.2",
    "heb": "אדר ב'"
    },
    {
    "num": "7.0",
    "heb": "ניסן"
    },
    {
    "num": "7.1",
    "heb": "יוט ניסן"
    },
    {
    "num": "8.0",
    "heb": "אייר"
    },
    {
    "num": "9.0",
    "heb": "סיון"
    },
    {
    "num": "10.0",
    "heb": "תמוז"
    },
    {
    "num": "11.0",
    "heb": "אב"
    },
    {
    "num": "12.0",
    "heb": "אלול"
    }
    ]





    export const listYears = [
        {
        "id": 750,
        "heb_num": "תש''נ",
        "num": 5750
        },
        {
        "id": 751,
        "heb_num": "תשנ''א",
        "num": 5751
        },
        {
        "id": 752,
        "heb_num": "תשנ''ב",
        "num": 5752
        },
        {
        "id": 753,
        "heb_num": "תשנ''ג",
        "num": 5753
        },
        {
        "id": 754,
        "heb_num": "תשנ''ד",
        "num": 5754
        },
        {
        "id": 755,
        "heb_num": "תשנ''ה",
        "num": 5755
        },
        {
        "id": 756,
        "heb_num": "תשנ''ו",
        "num": 5756
        },
        {
        "id": 757,
        "heb_num": "תשנ''ז",
        "num": 5757
        },
        {
        "id": 758,
        "heb_num": "תשנ''ח",
        "num": 5758
        },
        {
        "id": 759,
        "heb_num": "תשנ''ט",
        "num": 5759
        },
        {
        "id": 760,
        "heb_num": "תש''ס",
        "num": 5760
        },
        {
        "id": 761,
        "heb_num": "תשס''א",
        "num": 5761
        },
        {
        "id": 762,
        "heb_num": "תשס''ב",
        "num": 5762
        },
        {
        "id": 763,
        "heb_num": "תשס''ג",
        "num": 5763
        },
        {
        "id": 764,
        "heb_num": "תשס''ד",
        "num": 5764
        },
        {
        "id": 765,
        "heb_num": "תשס''ה",
        "num": 5765
        },
        {
        "id": 766,
        "heb_num": "תשס''ו",
        "num": 5766
        },
        {
        "id": 767,
        "heb_num": "תשס''ז",
        "num": 5767
        },
        {
        "id": 768,
        "heb_num": "תשס''ח",
        "num": 5768
        },
        {
        "id": 769,
        "heb_num": "תשס''ט",
        "num": 5769
        },
        {
        "id": 770,
        "heb_num": "תש''ע",
        "num": 5770
        },
        {
        "id": 771,
        "heb_num": "תשע''א",
        "num": 5771
        },
        {
        "id": 772,
        "heb_num": "תשע''ב",
        "num": 5772
        },
        {
        "id": 773,
        "heb_num": "תשע''ג",
        "num": 5773
        },
        {
        "id": 774,
        "heb_num": "תשע''ד",
        "num": 5774
        },
        {
        "id": 775,
        "heb_num": "תשע''ה",
        "num": 5775
        },
        {
        "id": 776,
        "heb_num": "תשע''ו",
        "num": 5776
        },
        {
        "id": 777,
        "heb_num": "תשע''ז",
        "num": 5777
        },
        {
        "id": 778,
        "heb_num": "תשע''ח",
        "num": 5778
        },
        {
        "id": 779,
        "heb_num": "תשע''ט",
        "num": 5779
        },
        {
        "id": 780,
        "heb_num": "תש''פ",
        "num": 5780
        },
        {
        "id": 781,
        "heb_num": "תשפ''א",
        "num": 5781
        },
        {
        "id": 782,
        "heb_num": "תשפ''ב",
        "num": 5782
        },
        {
        "id": 783,
        "heb_num": "תשפ''ג",
        "num": 5783
        },
        {
        "id": 784,
        "heb_num": "תשפ''ד",
        "num": 5784
        },
        {
        "id": 785,
        "heb_num": "תשפ''ה",
        "num": 5785
        },
        {
        "id": 786,
        "heb_num": "תשפ''ו",
        "num": 5786
        },
        {
        "id": 787,
        "heb_num": "תשפ''ז",
        "num": 5787
        },
        {
        "id": 788,
        "heb_num": "תשפ''ח",
        "num": 5788
        },
        {
        "id": 789,
        "heb_num": "תשפ''ט",
        "num": 5789
        },
        {
        "id": 790,
        "heb_num": "תש''צ",
        "num": 5790
        },
        {
        "id": 791,
        "heb_num": "תשצ''א",
        "num": 5791
        },
        {
        "id": 792,
        "heb_num": "תשצ''ב",
        "num": 5792
        },
        {
        "id": 793,
        "heb_num": "תשצ''ג",
        "num": 5793
        },
        {
        "id": 794,
        "heb_num": "תשצ''ד",
        "num": 5794
        },
        {
        "id": 795,
        "heb_num": "תשצ''ה",
        "num": 5795
        },
        {
        "id": 796,
        "heb_num": "תשצ''ו",
        "num": 5796
        },
        {
        "id": 797,
        "heb_num": "תשצ''ז",
        "num": 5797
        },
        {
        "id": 798,
        "heb_num": "תשצ''ח",
        "num": 5798
        },
        {
        "id": 799,
        "heb_num": "תשצ''ט",
        "num": 5799
        },
        {
        "id": 800,
        "heb_num": "ת''ת",
        "num": 5800
        },
        {
        "id": 801,
        "heb_num": "תת''א",
        "num": 5801
        },
        {
        "id": 802,
        "heb_num": "תת''ב",
        "num": 5802
        },
        {
        "id": 803,
        "heb_num": "תת''ג",
        "num": 5803
        },
        {
        "id": 804,
        "heb_num": "תת''ד",
        "num": 5804
        },
        {
        "id": 805,
        "heb_num": "תת''ה",
        "num": 5805
        },
        {
        "id": 806,
        "heb_num": "תת''ו",
        "num": 5806
        },
        {
        "id": 807,
        "heb_num": "תת''ז",
        "num": 5807
        },
        {
        "id": 808,
        "heb_num": "תת''ח",
        "num": 5808
        },
        {
        "id": 809,
        "heb_num": "תת''ט",
        "num": 5809
        },
        {
        "id": 810,
        "heb_num": "תת''י",
        "num": 5810
        },
        {
        "id": 811,
        "heb_num": "תתי''א",
        "num": 5811
        },
        {
        "id": 812,
        "heb_num": "תתי''ב",
        "num": 5812
        },
        {
        "id": 813,
        "heb_num": "תתי''ג",
        "num": 5813
        },
        {
        "id": 814,
        "heb_num": "תתי''ד",
        "num": 5814
        },
        {
        "id": 815,
        "heb_num": "תתט''ו",
        "num": 5815
        },
        {
        "id": 816,
        "heb_num": "תתט''ז",
        "num": 5816
        },
        {
        "id": 817,
        "heb_num": "תתי''ז",
        "num": 5817
        },
        {
        "id": 818,
        "heb_num": "תתי''ח",
        "num": 5818
        },
        {
        "id": 819,
        "heb_num": "תתי''ט",
        "num": 5819
        },
        {
        "id": 820,
        "heb_num": "תת''כ",
        "num": 5820
        },
        {
        "id": 821,
        "heb_num": "תתכ''א",
        "num": 5821
        },
        {
        "id": 822,
        "heb_num": "תתכ''ב",
        "num": 5822
        },
        {
        "id": 823,
        "heb_num": "תתכ''ג",
        "num": 5823
        },
        {
        "id": 824,
        "heb_num": "תתכ''ד",
        "num": 5824
        },
        {
        "id": 825,
        "heb_num": "תתכ''ה",
        "num": 5825
        },
        {
        "id": 826,
        "heb_num": "תתכ''ו",
        "num": 5826
        },
        {
        "id": 827,
        "heb_num": "תתכ''ז",
        "num": 5827
        },
        {
        "id": 828,
        "heb_num": "תתכ''ח",
        "num": 5828
        },
        {
        "id": 829,
        "heb_num": "תתכ''ט",
        "num": 5829
        },
        {
        "id": 830,
        "heb_num": "תת''ל",
        "num": 5830
        },
        {
        "id": 831,
        "heb_num": "תתל''א",
        "num": 5831
        },
        {
        "id": 832,
        "heb_num": "תתל''ב",
        "num": 5832
        },
        {
        "id": 833,
        "heb_num": "תתל''ג",
        "num": 5833
        },
        {
        "id": 834,
        "heb_num": "תתל''ד",
        "num": 5834
        },
        {
        "id": 835,
        "heb_num": "תתל''ה",
        "num": 5835
        },
        {
        "id": 836,
        "heb_num": "תתל''ו",
        "num": 5836
        },
        {
        "id": 837,
        "heb_num": "תתל''ז",
        "num": 5837
        },
        {
        "id": 838,
        "heb_num": "תתל''ח",
        "num": 5838
        },
        {
        "id": 839,
        "heb_num": "תתל''ט",
        "num": 5839
        },
        {
        "id": 840,
        "heb_num": "תת''מ",
        "num": 5840
        },
        {
        "id": 841,
        "heb_num": "תתמ''א",
        "num": 5841
        },
        {
        "id": 842,
        "heb_num": "תתמ''ב",
        "num": 5842
        },
        {
        "id": 843,
        "heb_num": "תתמ''ג",
        "num": 5843
        },
        {
        "id": 844,
        "heb_num": "תתמ''ד",
        "num": 5844
        },
        {
        "id": 845,
        "heb_num": "תתמ''ה",
        "num": 5845
        },
        {
        "id": 846,
        "heb_num": "תתמ''ו",
        "num": 5846
        },
        {
        "id": 847,
        "heb_num": "תתמ''ז",
        "num": 5847
        },
        {
        "id": 848,
        "heb_num": "תתמ''ח",
        "num": 5848
        },
        {
        "id": 849,
        "heb_num": "תתמ''ט",
        "num": 5849
        },
        {
        "id": 850,
        "heb_num": "תת''נ",
        "num": 5850
        },
        {
        "id": 851,
        "heb_num": "תתנ''א",
        "num": 5851
        },
        {
        "id": 852,
        "heb_num": "תתנ''ב",
        "num": 5852
        },
        {
        "id": 853,
        "heb_num": "תתנ''ג",
        "num": 5853
        },
        {
        "id": 854,
        "heb_num": "תתנ''ד",
        "num": 5854
        },
        {
        "id": 855,
        "heb_num": "תתנ''ה",
        "num": 5855
        },
        {
        "id": 856,
        "heb_num": "תתנ''ו",
        "num": 5856
        },
        {
        "id": 857,
        "heb_num": "תתנ''ז",
        "num": 5857
        },
        {
        "id": 858,
        "heb_num": "תתנ''ח",
        "num": 5858
        },
        {
        "id": 859,
        "heb_num": "תתנ''ט",
        "num": 5859
        },
        {
        "id": 860,
        "heb_num": "תת''ס",
        "num": 5860
        },
        {
        "id": 861,
        "heb_num": "תתס''א",
        "num": 5861
        },
        {
        "id": 862,
        "heb_num": "תתס''ב",
        "num": 5862
        },
        {
        "id": 863,
        "heb_num": "תתס''ג",
        "num": 5863
        },
        {
        "id": 864,
        "heb_num": "תתס''ד",
        "num": 5864
        },
        {
        "id": 865,
        "heb_num": "תתס''ה",
        "num": 5865
        },
        {
        "id": 866,
        "heb_num": "תתס''ו",
        "num": 5866
        },
        {
        "id": 867,
        "heb_num": "תתס''ז",
        "num": 5867
        },
        {
        "id": 868,
        "heb_num": "תתס''ח",
        "num": 5868
        },
        {
        "id": 869,
        "heb_num": "תתס''ט",
        "num": 5869
        },
        {
        "id": 870,
        "heb_num": "תת''ע",
        "num": 5870
        },
        {
        "id": 871,
        "heb_num": "תתע''א",
        "num": 5871
        },
        {
        "id": 872,
        "heb_num": "תתע''ב",
        "num": 5872
        },
        {
        "id": 873,
        "heb_num": "תתע''ג",
        "num": 5873
        },
        {
        "id": 874,
        "heb_num": "תתע''ד",
        "num": 5874
        },
        {
        "id": 875,
        "heb_num": "תתע''ה",
        "num": 5875
        },
        {
        "id": 876,
        "heb_num": "תתע''ו",
        "num": 5876
        },
        {
        "id": 877,
        "heb_num": "תתע''ז",
        "num": 5877
        },
        {
        "id": 878,
        "heb_num": "תתע''ח",
        "num": 5878
        },
        {
        "id": 879,
        "heb_num": "תתע''ט",
        "num": 5879
        },
        {
        "id": 880,
        "heb_num": "תת''פ",
        "num": 5880
        },
        {
        "id": 881,
        "heb_num": "תתפ''א",
        "num": 5881
        },
        {
        "id": 882,
        "heb_num": "תתפ''ב",
        "num": 5882
        },
        {
        "id": 883,
        "heb_num": "תתפ''ג",
        "num": 5883
        },
        {
        "id": 884,
        "heb_num": "תתפ''ד",
        "num": 5884
        },
        {
        "id": 885,
        "heb_num": "תתפ''ה",
        "num": 5885
        },
        {
        "id": 886,
        "heb_num": "תתפ''ו",
        "num": 5886
        },
        {
        "id": 887,
        "heb_num": "תתפ''ז",
        "num": 5887
        },
        {
        "id": 888,
        "heb_num": "תתפ''ח",
        "num": 5888
        },
        {
        "id": 889,
        "heb_num": "תתפ''ט",
        "num": 5889
        },
        {
        "id": 890,
        "heb_num": "תת''צ",
        "num": 5890
        },
        {
        "id": 891,
        "heb_num": "תתצ''א",
        "num": 5891
        },
        {
        "id": 892,
        "heb_num": "תתצ''ב",
        "num": 5892
        },
        {
        "id": 893,
        "heb_num": "תתצ''ג",
        "num": 5893
        },
        {
        "id": 894,
        "heb_num": "תתצ''ד",
        "num": 5894
        },
        {
        "id": 895,
        "heb_num": "תתצ''ה",
        "num": 5895
        },
        {
        "id": 896,
        "heb_num": "תתצ''ו",
        "num": 5896
        },
        {
        "id": 897,
        "heb_num": "תתצ''ז",
        "num": 5897
        },
        {
        "id": 898,
        "heb_num": "תתצ''ח",
        "num": 5898
        },
        {
        "id": 899,
        "heb_num": "תתצ''ט",
        "num": 5899
        },
        {
        "id": 900,
        "heb_num": "תת''ק",
        "num": 5900
        },
        {
        "id": 901,
        "heb_num": "תתק''א",
        "num": 5901
        },
        {
        "id": 902,
        "heb_num": "תתק''ב",
        "num": 5902
        },
        {
        "id": 903,
        "heb_num": "תתק''ג",
        "num": 5903
        },
        {
        "id": 904,
        "heb_num": "תתק''ד",
        "num": 5904
        },
        {
        "id": 905,
        "heb_num": "תתק''ה",
        "num": 5905
        },
        {
        "id": 906,
        "heb_num": "תתק''ו",
        "num": 5906
        },
        {
        "id": 907,
        "heb_num": "תתק''ז",
        "num": 5907
        },
        {
        "id": 908,
        "heb_num": "תתק''ח",
        "num": 5908
        },
        {
        "id": 909,
        "heb_num": "תתק''ט",
        "num": 5909
        },
        {
        "id": 910,
        "heb_num": "תתק''י",
        "num": 5910
        },
        {
        "id": 911,
        "heb_num": "תתקי''א",
        "num": 5911
        },
        {
        "id": 912,
        "heb_num": "תתקי''ב",
        "num": 5912
        },
        {
        "id": 913,
        "heb_num": "תתקי''ג",
        "num": 5913
        },
        {
        "id": 914,
        "heb_num": "תתקי''ד",
        "num": 5914
        },
        {
        "id": 915,
        "heb_num": "תתקט''ו",
        "num": 5915
        },
        {
        "id": 916,
        "heb_num": "תתקט''ז",
        "num": 5916
        },
        {
        "id": 917,
        "heb_num": "תתקי''ז",
        "num": 5917
        },
        {
        "id": 918,
        "heb_num": "תתקי''ח",
        "num": 5918
        },
        {
        "id": 919,
        "heb_num": "תתקי''ט",
        "num": 5919
        },
        {
        "id": 920,
        "heb_num": "תתק''כ",
        "num": 5920
        },
        {
        "id": 921,
        "heb_num": "תתקכ''א",
        "num": 5921
        },
        {
        "id": 922,
        "heb_num": "תתקכ''ב",
        "num": 5922
        },
        {
        "id": 923,
        "heb_num": "תתקכ''ג",
        "num": 5923
        },
        {
        "id": 924,
        "heb_num": "תתקכ''ד",
        "num": 5924
        },
        {
        "id": 925,
        "heb_num": "תתקכ''ה",
        "num": 5925
        },
        {
        "id": 926,
        "heb_num": "תתקכ''ו",
        "num": 5926
        },
        {
        "id": 927,
        "heb_num": "תתקכ''ז",
        "num": 5927
        },
        {
        "id": 928,
        "heb_num": "תתקכ''ח",
        "num": 5928
        },
        {
        "id": 929,
        "heb_num": "תתקכ''ט",
        "num": 5929
        },
        {
        "id": 930,
        "heb_num": "תתק''ל",
        "num": 5930
        },
        {
        "id": 931,
        "heb_num": "תתקל''א",
        "num": 5931
        },
        {
        "id": 932,
        "heb_num": "תתקל''ב",
        "num": 5932
        },
        {
        "id": 933,
        "heb_num": "תתקל''ג",
        "num": 5933
        },
        {
        "id": 934,
        "heb_num": "תתקל''ד",
        "num": 5934
        },
        {
        "id": 935,
        "heb_num": "תתקל''ה",
        "num": 5935
        },
        {
        "id": 936,
        "heb_num": "תתקל''ו",
        "num": 5936
        },
        {
        "id": 937,
        "heb_num": "תתקל''ז",
        "num": 5937
        },
        {
        "id": 938,
        "heb_num": "תתקל''ח",
        "num": 5938
        },
        {
        "id": 939,
        "heb_num": "תתקל''ט",
        "num": 5939
        },
        {
        "id": 940,
        "heb_num": "תתק''מ",
        "num": 5940
        },
        {
        "id": 941,
        "heb_num": "תתקמ''א",
        "num": 5941
        },
        {
        "id": 942,
        "heb_num": "תתקמ''ב",
        "num": 5942
        },
        {
        "id": 943,
        "heb_num": "תתקמ''ג",
        "num": 5943
        },
        {
        "id": 944,
        "heb_num": "תתקמ''ד",
        "num": 5944
        },
        {
        "id": 945,
        "heb_num": "תתקמ''ה",
        "num": 5945
        },
        {
        "id": 946,
        "heb_num": "תתקמ''ו",
        "num": 5946
        },
        {
        "id": 947,
        "heb_num": "תתקמ''ז",
        "num": 5947
        },
        {
        "id": 948,
        "heb_num": "תתקמ''ח",
        "num": 5948
        },
        {
        "id": 949,
        "heb_num": "תתקמ''ט",
        "num": 5949
        }
        ]