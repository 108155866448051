import React, { useEffect, useRef, useState } from "react"
import { PiCaretDoubleRight } from "react-icons/pi";
import * as iconAi from "react-icons/ai";
import Fireworks from "fireworks-js";
import { filter, orderBy, sortBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserState, { ListState } from "../../models/user-model";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setlistSlice } from "../../redux/listState";
import UpdateAvtuce from "./updateAvtuce";
import { BiSolidEdit } from "react-icons/bi";
import { BsToggleOff, BsToggleOn, BsToggles } from "react-icons/bs";
import SheetXlsx from "../sistem/import/SheetXlsx";
import apiAxios from "../../redux/apiAxios";
import { MdOutlineLockPerson } from "react-icons/md";
import { ListMagbisState } from "./magbis_model";
import { Button } from "react-bootstrap";
import { title } from "process";
import { act } from "react-dom/test-utils";




export default function CardsList() {
    const userSlice: UserState = useSelector((state: any) => state.userSlice);
    const listSlice: ListState = useSelector((state: any) => state.listSlice);
    const dispatch = useDispatch();
    const headers = { 'x-api-key': userSlice.token }
    const baseUrl: string = process.env.REACT_APP_API_URL!
    const { register } = useForm();
    const [ar, setAr] = useState<ListMagbisState[]>([]);
    const [arList, setArList] = useState<ListMagbisState[]>([]);
    const [selectYear] = useState(listSlice.year_Magbis);
    const [idstudent, setIdsudent] = useState(userSlice.idcards);
    const [Showdetails, seShowdetails] = useState(false);
    const [isActive, setIsActive] = useState({ active: 2, title: 'הכל', filter: '', icon: <BsToggles size={34} /> });

    // const [prosent, setProsent] = useState(0);
    const nav = useNavigate();
    const containerRef = useRef<HTMLDivElement | null>(null);



    useEffect(() => {
        disRedax();
    }, [])

    useEffect(() => {
        doApi();
    }, [idstudent, isActive])

    // useEffect(() => {
    //     doProsent();
    // }, [ar])


    //מערך למסנן סופי לא סופי
    const activAr = async () => {
        switch (isActive.active) {
            case 0:
                setIsActive({ active: 2, title: 'הכל', filter: '', icon: <BsToggles size={34} /> })
                break;
            case -1:
                setIsActive({ active: 0, title: 'לא סופי', filter: 'Active<>0', icon: <BsToggleOff size={34} /> })
                break;
            case 2:
                setIsActive({ active: -1, title: 'סופי', filter: 'Active=0', icon: <BsToggleOn size={34} /> })
                break;
        }
    }




    const doApi = async () => {
        setArList([]);
        try {
            if (idstudent === undefined) { setAr([]); setArList([]); return; };
            const url = idstudent == 0 ? baseUrl + `magbis/listMagbis/${selectYear}` : baseUrl + `magbis/listMagbisYedidim/${idstudent}/${selectYear}`;
            const data = (await apiAxios.get<ListMagbisState[]>(url, { headers })).data

            //אם יש סינון לסופי
            let filter = []
            if (isActive.active != 2) {
                filter = data.filter(item => item.Active === isActive.active)
            } else {
                filter = data
            }

            const imp: any = filter.map((item: any) => ({ ...item, fulname: item.FirstName + ' ' + item.FamilyName + ' ' + item.Address + ' ' + item.Region }))
            setAr(imp);
            setArList(imp);
            fireworks1();
        } catch (error: any) {
            if (error && error.response && error.response.status === 401) {
                // Handle the error
            }
        }

    }

    // const doProsent = async () => {
    //     const list = ar.filter(item => item.Active)
    //     const totalSum = list.reduce((acc, obj) => Number(acc) + Number(obj.prosent), 0);
    //     const totalSum2 = Number((totalSum / list.length).toFixed(0));

    //     const average = Number((ar.length + 30) / 2);
    //     console.log(average);

    //     const totalSum3 = Number((totalSum2 / average * list.length).toFixed(0));
    //     setProsent(totalSum3 || 0);
    // }


    const C2clink = async (tel: string) => {
        if (userSlice.role !== 2) return;
        if (!tel) return;
        const url = process.env.REACT_APP_API_URL! + `tools/c2clink/coll`
        return await apiAxios.post(url, { tel }, { headers })
    }

    const disRedax = async () => {
        dispatch(setlistSlice({
            ...listSlice,
            Selectyedidym: (await apiAxios.get(baseUrl + "magbis/listYedidim/" + selectYear, { headers })).data
        }));
    }

    //כלאסים לשדות בטבלה
    const clNataKe = "h-25 fs-6 fw-lighter p-0 mhp-10"
    const clNata = "h-25 fs-6 p-0"
    const clNataTel = "p-0 px-3 link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"


    const upFilterAr = (e: any) => {
        const fl: string = e

        const fe = fl.split(" ");
        const filter = ar.filter(row => fe.every(keyWord => row.fulname.includes(keyWord))
        )

        { fl ? setArList(filter) : setArList(ar) }
    }


    //זיקוקין
    const fireworks1 = () => {
        const container = containerRef.current;
        if (container) {
            const options: any = {
                rocketsPoint: { min: 0, max: 100 }, // תוקן לשימוש במבנה MinMax
                hue: { min: 0, max: 360 },
                delay: { min: 15, max: 30 },
                speed: 2,
                acceleration: 1.05,
                friction: 0.98,
                gravity: 1.5,
                particles: 50,
                trace: 3,
                explosion: 5,
                autoresize: true,
                brightness: { min: 50, max: 80 }, // תוקן למבנה MinMax
                decay: { min: 0.015, max: 0.03 }, // תוקן למבנה MinMax
                mouse: { click: false, move: false, max: 1 },
                boundaries: {
                    x: 50,
                    y: 50,
                    width: container.clientWidth,
                    height: container.clientHeight,
                },
                sound: { enable: false },
            };

            const fireworks = new Fireworks(container, options);
            fireworks.start();


            setTimeout(() => {
                return () => {
                    fireworks.stop();
                };
            }, 10000);
        }
    }


    return (
        <div ref={containerRef}>
            <div className="d-flex justify-content-around sticky-top bg-body">
                <h2 className="display-1">רשימת מגבית</h2>

                {userSlice.role > 0 && listSlice.Selectyedidym && (
                    <div className=' m-4 text-center border rounded'>
                        <p className=' m-0 fw-bold'>מתרים</p>
                        <select  {...register("idstudent", { required: false })} defaultValue={idstudent} onChange={(e) => setIdsudent(Number(e.target.value))} className=' btn p-0' >
                            <option value='0'>כל המתרימים</option>
                            {listSlice.Selectyedidym.map((item, key) => (
                                <option value={item.idcards} > {item.FirstName + ' ' + item.FamilyName}</option>
                            ))}
                        </select>
                    </div>
                )}

                <input className="btn border m-4 " onInput={(e: any) => upFilterAr(e.target.value)} placeholder="חפש..."  ></input>

                <button className="btn border m-4" title={isActive.title} onClick={activAr}  >{isActive.icon}</button>

                {/* <h2 className={prosent > 99 ? "display-1 text-success" : "display-2 text-danger"}>{prosent}{prosent > 100 && <BsGraphUpArrow size={40} />}</h2> */}
                <SheetXlsx className="btn border m-4" arList={arList.map(item => ({
                    כרטיס: item.idcard,
                    מצב: item.Active ? "סופי" : "לא סופי",
                    שם: item.FirstName,
                    משפחה: item.FamilyName,
                    'שם אב': item.FatherName,
                    התחייבות: item.Price,
                    התקבל: item.accepted,
                    מטבע: item.NameCurrencyH,
                    'אמצעי תשלום': item.Paymentmethod,
                    'פלפון': item.Mobile1,
                    'טלפון בית': item.Telephone,
                    הערות: item.Notes,
                    כתובת: item.Address,
                    בנין: item.Building,
                    בית: item.Home,
                    עיר: item.City,
                }))} nameFile='פירוט מגבית' sizeIcon={40} />


            </div>
            <table className="table table-striped table-hover ">
                <thead>
                    <tr className="sticky-top bg-body ">
                        <th></th>
                        <th>#</th>
                        <th onClick={() => setArList(sortBy(arList, 'idcards'))} onDoubleClick={() => setArList(orderBy(arList, 'idcards', 'desc'))} >כרטיס</th>
                        <th onClick={() => setArList(sortBy(arList, 'Active'))} onDoubleClick={() => setArList(orderBy(arList, 'Active', 'desc'))} >מצב</th>
                        <th onClick={() => setArList(sortBy(arList, 'Mobile1'))} onDoubleClick={() => setArList(orderBy(arList, 'Mobile1', 'desc'))} >פלפון</th>
                        {/* <th onClick={() => setArList(sortBy(arList, 'prosent'))} onDoubleClick={() => setArList(orderBy(arList, 'prosent', 'desc'))} >אחוז</th> */}
                        <th onClick={() => setArList(sortBy(arList, 'FirstName'))} onDoubleClick={() => setArList(orderBy(arList, 'FirstName', 'desc'))} >שם</th>
                        <th onClick={() => setArList(sortBy(arList, 'FamilyName'))} onDoubleClick={() => setArList(orderBy(arList, 'FamilyName', 'desc'))} >משפחה</th>
                        <th onClick={() => setArList(sortBy(arList, 'Price'))} onDoubleClick={() => setArList(orderBy(arList, 'Price', 'desc'))} >מגבית</th>
                        <th onClick={() => setArList(sortBy(arList, 'accepted'))} onDoubleClick={() => setArList(orderBy(arList, 'accepted', 'desc'))} >התקבל בשנה הקודם</th>
                        {/* <th onClick={() => setArList(sortBy(arList, 'balance'))} onDoubleClick={() => setArList(orderBy(arList, 'balance', 'desc'))} >יתרה</th> */}
                        <th onClick={() => setArList(sortBy(arList, 'NameCurrencyH'))} onDoubleClick={() => setArList(orderBy(arList, 'NameCurrencyH', 'desc'))} >מטבע</th>
                        <th onClick={() => setArList(sortBy(arList, 'Paymentmethod'))} onDoubleClick={() => setArList(orderBy(arList, 'Paymentmethod', 'desc'))} >אמצעי</th>

                        {Showdetails ? (
                            <>
                                <th><span onClick={() => seShowdetails(false)} > <iconAi.AiOutlineMenuUnfold size={28} /> </span></th>
                                <th onClick={() => setArList(sortBy(arList, 'FatherName'))} onDoubleClick={() => setArList(orderBy(arList, 'FatherName', 'desc'))} >שם אב</th>
                                <th onClick={() => setArList(sortBy(arList, 'chsnoy'))} onDoubleClick={() => setArList(orderBy(arList, 'chsnoy', 'desc'))} >חותנו</th>
                                <th onClick={() => setArList(sortBy(arList, 'Address'))} onDoubleClick={() => setArList(orderBy(arList, 'Address', 'desc'))} >כתובת</th>
                                <th onClick={() => setArList(sortBy(arList, 'Building'))} onDoubleClick={() => setArList(orderBy(arList, 'Building', 'desc'))} >מספר</th>
                                <th onClick={() => setArList(sortBy(arList, 'Region'))} onDoubleClick={() => setArList(orderBy(arList, 'Region', 'desc'))} >שכונה</th>
                                <th onClick={() => setArList(sortBy(arList, 'City'))} onDoubleClick={() => setArList(orderBy(arList, 'City', 'desc'))} >עיר</th>
                            </>
                        ) :
                            <th><span onClick={() => seShowdetails(true)} > <iconAi.AiOutlineMenuFold size={35} /> </span></th>
                        }
                        <th ></th>
                    </tr>
                </thead>
                {!arList.length ? <h1 className="text-center">מוריד נתונים...</h1> : null}
                <tbody>

                    {arList.map((item, i) => {
                        return (
                            <tr key={item.idcard} >
                                <td ><button onClick={() => { nav(`/magbis?idcard=${item.idcard}&year=${selectYear - 1}`) }} className="btn" > <PiCaretDoubleRight size={20} /> </button></td>
                                <td className={clNataKe}>{i + 1}</td>
                                <td className={clNataKe}>{item.idcard}</td>
                                {item.Realized ?
                                    <td><p className='btn' title='נעול' > <MdOutlineLockPerson size={26} /> </p> </td>
                                    : <td><p className='btn' title={item.Active == 0 ? 'לא סופי' : 'סופי'} > {item.Active == 0 ? <BsToggleOff size={26} /> : <BsToggleOn size={26} />} </p> </td>}
                                <td className={clNataTel} onClick={() => C2clink(item.Mobile1)} > {item.Mobile1} </td>
                                {/* <td className={Number(item.prosent) < 100 ? "h-25 fs-6 p-0 fw-bold text-danger" : "h-25 fs-6 p-0 fw-bold text-success"}>{item.prosent} %</td> */}
                                <td className="h-25 fs-6 p-0 fw-bold">{item.FirstName}</td>
                                <td className="h-25 fs-6 p-0 fw-bold">{item.FamilyName}</td>
                                <td className={clNata}>{item.Price}</td>
                                <td className={clNata + " fw-bold text-success"}>{item.accepted.toFixed(0)}</td>
                                {/* <td className={clNata}>{item.balance}</td> */}
                                <td className={clNata}>{item.NameCurrencyH}</td>
                                <td className={clNata}>{item.Paymentmethod}</td>
                                <td><UpdateAvtuce item={item} doApi={doApi} headers={headers} icon={<BiSolidEdit size={20} />} /></td>
                                {Showdetails ? (
                                    <>
                                        <td className={clNata}>{item.FatherName}</td>
                                        <td className={clNata}>{item.chsnoy}</td>
                                        <td className={clNata}>{item.Address}</td>
                                        <td className={clNata}>{item.Building + (item.Home ? "/" + item.Home : "")}</td>
                                        <td className={clNata}>{item.Region}</td>
                                        <td className={clNata}>{item.City}</td>
                                    </>
                                ) : null}
                            </tr>
                        )
                    })}


                </tbody>
            </table>

        </div>

    )


}