import dayjs from "dayjs";
import { getH } from "../../redux/listMonthH&YearH";
import { Accepted_State } from "./magbis_model";
import { useEffect, useState } from "react";
import apiAxios from "../../redux/apiAxios";


export default function MagbisAccepted(props:any) {
    const { idCard, year ,headers} = props
    const [arList, setArList] = useState<Accepted_State[]>([]);



    useEffect(() => {
        doApi()
    }, [idCard, year])

    //ייבוא פרטים לפי כרטיס ושנה
    const doApi = async () => {
        setArList([]);
        if (idCard === undefined || year === undefined) { return [] }
        try {
            const url: string = process.env.REACT_APP_API_URL! + "magbis/accepteds/" + idCard + "/" + year + "/";
            const data = (await apiAxios.get<Accepted_State[]>(url, { headers })).data
             // console.log(await data);

            setArList(data);
            console.log(arList);


        } catch (error: any) {
            if (error.response.status === 401) {

            }
        }
    }


  
    return (
    <div>

<h4 className="p-3">פירוט התקבל</h4>

    <table className="table table-striped table-hover">
    <thead>

            <tr>

                <th className="text-center " >#</th>
                <th className="text-center "  >סכום</th>
                <th className="text-center "  >אמצעי</th>
                <th className="text-center "  >תאריך</th>
                <th className="text-center "  >עברי</th>
                <th className="text-center "  >חודש</th>
                <th className="text-center "  >חזר</th>


                <th ></th>
            </tr>
        </thead>
        <tbody>

            {arList.map((item, i) => {
                return (
                    <tr key={item.idData} >

                        <td className="h-25 fs-6 p-0 text-center">{i+1}</td>
                        <td className="h-25 fs-6 p-0 text-center" title={item.TotalIL + ' שקל'} >{item.Price + " " + item.NameCurrencyH}</td>
                        <td className="h-25 fs-6 p-0 text-center" title={'שם בע"ח ' + item.NaneCich} >{item.Paymentmethod}</td>
                        <td className="h-25 fs-6 p-0 text-center">{item.DateAction ? dayjs(item.DateAction).format("DD/MM/YYYY") : ""}</td>
                        <td className="h-25 fs-6 p-0 text-center">{item.DateActionH}</td>
                        <td className="h-25 fs-6 p-0 text-center ">{(getH.MontH(item.MonthAction)) + " " + getH.YearH(item.YearAction)}</td>
                        <td className="h-25 fs-6 p-0 text-center">{item.ReasonBack}</td>



                    </tr>
                )
            })}


        </tbody>
    </table>



</div>


    
  )
}
