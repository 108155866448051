import dayjs from 'dayjs'
import React, { useState } from 'react'
import { BsEnvelope, BsEnvelopeOpen, BsFillEnvelopeFill, BsToggleOff, BsToggleOn } from 'react-icons/bs'
import { useSelector } from 'react-redux';
import UserState, { ListState } from '../../models/user-model';
import './tasks.css'
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL

export default function TasksBox(props: any) {
  const arList = props.arList
  const listSlice: ListState = useSelector((state: any) => state.listSlice);
  const [done, setdone] = useState(!arList.done)
  const [newMessage, setNewMessage] = useState(!arList.NewMessage)
  const userSlice: UserState = useSelector((state: any) => state.userSlice);
  // וואלעס - לבדוק למה לא מופיע הסמן הנכון של נקרא ולא נקרא
  //וגם למה לא מראה כאן השדות לפי TS
  console.log(arList, newMessage, done);

  // const doc = arList.desc.split('\n').join("<br/>")
  const doc = arList.desc

  let TaskTasks = 'רגיל';
  let TaskClass = 'dark';
  switch (arList.TaskTasks) {
    case -1: TaskTasks = 'לא דחוף';
      TaskClass = 'primary';
      break;
    case 1: TaskTasks = 'דחוף';
      TaskClass = 'warning';
      break;
    case 2: TaskTasks = 'דחוף מאוד';
      TaskClass = 'danger';
      break;
    default: TaskTasks = 'רגיל';
      TaskClass = 'dark';

  }

  const upNewMessage = (id: number) => {
    try {
      if (newMessage) {
        const url: string = baseUrl + "tasks/newMessage1/" + id;
        axios.get(url, { headers: { 'x-api-key': userSlice.token } })
      } else {
        const url: string = baseUrl + "tasks/newMessage0/" + id;
        axios.get(url, { headers: { 'x-api-key': userSlice.token } })
      }
      setNewMessage(!newMessage)
    } catch (error) {
      console.log(error);
    }
  }

  const upDone = (id: number) => {
    try {
      if (done) {
        const url: string = baseUrl + "tasks/done1/" + id;
        axios.get(url, { headers: { 'x-api-key': userSlice.token } })
      } else {
        const url: string = baseUrl + "tasks/done0/" + id;
        axios.get(url, { headers: { 'x-api-key': userSlice.token } })
      }
      setdone(!done)
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className='box d-flex justify-content-between flex-column border m-3 rounded-3 shadow '>

      <div className='d-flex justify-content-between rounded-3 border m-0 '>


        <button onClick={() => { upNewMessage(arList.idTasks) }} className='btn' >
          <span role="status">   {newMessage ? <BsEnvelopeOpen size={26} /> : <BsFillEnvelopeFill size={26} color='black' />}  </span>
        </button>


        <h5 className='align-middle' >{arList.SubTaskType}</h5>
        <button onClick={() => { upDone(arList.idTasks) }} className='btn' title={done ? 'המשימה לא בוצע' : 'המשימה בוצע'} >
          {done ? <BsToggleOff size={26} /> : <BsToggleOn size={26} />}
        </button>

      </div>

      <div className='p-3' >

        <p className='textHtml' title={arList.desc} >
          {arList.desc}
        </p>
      </div>


      <div className='d-flex justify-content-between mt-3 shadow rounded-3 px-3 pt-2  border '>
        <h5 className={"text-" + TaskClass}>
          {TaskTasks}
        </h5>
        <p >{arList.UserChange && listSlice.select_userNames.filter(x => (x.IdUser == arList.UserChange))[0].NameUser}</p>
        <div className='d-flex'>
          <p className='px-3' >{dayjs(arList.dateToDo).format("DD/MM/YYYY")}</p>
          <p>{arList.dateToDoH}</p>
        </div>

      </div>


    </div>
  )
}
