import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io, Socket } from 'socket.io-client';
import './Chat.css';  // קובץ CSS לעיצוב
import UserState from '../../models/user-model';
import { useSelector } from 'react-redux';
import { BsSend } from 'react-icons/bs';
import dayjs from 'dayjs';
const baseUrl: string = process.env.REACT_APP_API_URL!; // משתנה סביבה עבור הכתובת הבסיסית של ה-API


// הגדרת ממשק עבור הודעה
interface Message {
    id: number;
    conversation_id: number;
    user_id: number;
    content: string;
    NameUser: string;
    created_at: Date;
}

// הגדרת ממשק עבור שיחה
interface Conversation {
    id: number;
    client_id: number;
    topic: string;
    recipients: number[]; // נמענים בשיחה
}

interface User {
    userId: number;
    NameUser: string;
    socketId: string;
}


// יצירת חיבור ל-Socket.IO
const socket: Socket = io(baseUrl);


const Chat: React.FC<{ clientCode: string }> = ({ clientCode }) => {
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [newConversationTopic, setNewConversationTopic] = useState<string>('');
    const [typingUsers, setTypingUsers] = useState<{ [key: number]: string }>({});
    const [onlineUsers, setOnlineUsers] = useState<User[]>([]);
    const [selectedRecipients, setSelectedRecipients] = useState<number[]>([]); // נמענים שנבחרו לשיחה חדשה
    const messagesEndRef = useRef<HTMLDivElement | null>(null); // Ref עבור סיום רשימת ההודעות

    const userSlice: UserState = useSelector((state: any) => state.userSlice);
    const idUser: Number = userSlice.idcards; // מזהה משתמש נוכחי


    useEffect(() => {
        // בקשת GET לטעינת השיחות
        // axios.get(baseUrl + `chat/conversations/${clientCode}`)
        axios.get(baseUrl + `chat/conversations`)
            .then(response => {
                console.log('Conversations:', response.data); // בדיקת תגובה מהשרת
                setConversations(response.data);
            })
            .catch(error => console.error('Error fetching conversations:', error));

        socket.emit('userConnected', { userId: idUser, NameUser: userSlice.NameUser });


        socket.on('newMessage', (message: Message) => {
            const playNotificationSound = () => {
                const audio = new Audio('/Speech Off.wav');
                audio.play();
            };
            if (message.conversation_id === selectedConversation?.id) {
                setMessages(prevMessages => [...prevMessages, message]);
                playNotificationSound();
            }
        });

        socket.on('userTyping', (data: { conversationId: number; userId: number, NameUser: string }) => {
            if (data.conversationId === selectedConversation?.id && data.userId !== idUser) {
                setTypingUsers((prevTypingUsers) => ({ ...prevTypingUsers, [data.userId]: `${data.NameUser}  מקליד 🖋️...` }));
                setTimeout(() => {
                    setTypingUsers((prevTypingUsers) => {
                        const newTypingUsers = { ...prevTypingUsers };
                        delete newTypingUsers[data.userId];
                        return newTypingUsers;
                    });
                }, 3000); // מסיר את החיווי לאחר 3 שניות
            }
        });

        socket.on('usersOnline', (users: User[]) => {
            setOnlineUsers(users);
        });

        return () => {
            socket.off('newMessage');
            socket.off('userTyping');
            socket.off('usersOnline');
        };
    }, [clientCode, selectedConversation]);

    const fetchMessages = (conversationId: number) => {
        axios.get(baseUrl + `chat/messages/${conversationId}`)
            .then(response => setMessages(response.data))
            .catch(error => console.error('Error fetching messages:', error));
    };



    const sendMessage = () => {
        if (newMessage.trim() && selectedConversation) {
            const messageData = {
                conversation_id: selectedConversation.id,
                user_id: userSlice.idcards,
                NameUser: userSlice.NameUser,
                content: newMessage
            };

            socket.emit('sendMessage', messageData);
            setNewMessage('');
        }
        
    };

    const handleTyping = () => {
        if (selectedConversation) {
            socket.emit('typing', { conversationId: selectedConversation.id, userId: idUser, NameUser: userSlice.NameUser });
        }
    };

    const createNewConversation = () => {
        if (newConversationTopic.trim()) {
            axios.post(baseUrl + 'chat/conversations', { client_id: idUser, topic: newConversationTopic })
                .then(response => {
                    setConversations(prevConversations => [...prevConversations, response.data]);
                    setNewConversationTopic('');
                    setSelectedRecipients([]);
                })
                .catch(error => console.error('Error creating conversation:', error));
        }
    };

    const isUserOnline = (userId: number) => {
        return onlineUsers.some(user => user.userId === userId);
    };

    const toggleRecipient = (userId: number) => {
        setSelectedRecipients((prevRecipients) => {
            if (prevRecipients.includes(userId)) {
                return prevRecipients.filter(recipient => recipient !== userId);
            } else {
                return [...prevRecipients, userId];
            }
        });
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      };
      
      useEffect(() => {
        scrollToBottom();
      }, [messages]); // כאשר ההודעות מתעדכנות, גלול לתחתית
      

    const handleShiftEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && e.ctrlKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="chat-container">
            <div className="sidebar shadow">
                <h2>שיחות</h2>
                <div className="new-conversation">
                    <input
                        type="text"
                        value={newConversationTopic}
                        onChange={(e) => setNewConversationTopic(e.target.value)}
                        placeholder="נושא לשיחה חדשה"
                    />
                    {/* <h3>בחר נמענים:</h3>
                    <ul>
                    {onlineUsers.map(user => (
                            <li key={user.userId}>
                                <label>
                                <input
                                type="checkbox"
                                checked={selectedRecipients.includes(user.userId)}
                                onChange={() => toggleRecipient(user.userId)}
                                />
                                {user.username} {isUserOnline(user.userId) ? "🟢" : "🔴"}
                                </label>
                                </li>
                                ))}
                                </ul> */}
                    <button onClick={createNewConversation}>פתח שיחה חדשה</button>
                    <div className="users-online">
                        <h2>משתמשים מחוברים:</h2>
                        <ul>
                            {onlineUsers.map(user => (
                                <li key={user.userId}>
                                     {isUserOnline(user.userId) ? "🟢" : "🔴"} {user.NameUser}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <ul className="conversation-list">
                    {conversations.length > 0 ? (
                        conversations.map(conv => (
                            <li
                                key={conv.id}
                                className={`conversation-item ${selectedConversation?.id === conv.id ? 'selected' : ''}`}
                                onClick={() => {
                                    setSelectedConversation(conv);
                                    fetchMessages(conv.id);
                                }}
                            >
                                {conv.topic}
                            </li>
                        ))
                    ) : (
                        <p>אין שיחות זמינות</p>
                    )}
                </ul>
            </div>
            <div className="chat-content">
                {selectedConversation ? (
                    <>
                        <h2>{selectedConversation.topic}</h2>
                        <ul className="message-list">
                            {messages && messages.map(msg => (
                                <div className='message-item'>
                                    <li key={msg.id} className={"message-message shadow " + (msg.user_id === idUser ? 'outgoing' : 'incoming')}>
                                        <strong>{msg.user_id !== idUser ? msg.NameUser : 'אני - '} {isUserOnline(msg.user_id) ? "🟢 " : "🔴 "} 
                                            {dayjs(msg.created_at).isSame(dayjs(), 'day') ?
                                                  dayjs(msg.created_at).format("hh:mm")
                                                : dayjs(msg.created_at).format(" DD/MM/YY hh:mm")}</strong>
                                        <div>{msg.content}</div>
                                    </li>
                                </div>
                            ))}
                            <div ref={messagesEndRef} /> {/* אלמנט לסימון סוף רשימת ההודעות */}
                        </ul>
                        <div>
                            {Object.keys(typingUsers).length > 0 && (
                                <p>{Object.values(typingUsers).join(', ')}</p>
                            )}
                            <div className="message-input">
                                <textarea
                                    value={newMessage}
                                    onChange={(e) => {
                                        setNewMessage(e.target.value);
                                        handleTyping();
                                    }}
                                    onKeyDown={handleShiftEnter}
                                    placeholder="הקלד הודעה..."
                                />
                                <button onClick={sendMessage}><BsSend /></button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="no-conversation">בחר שיחה או פתח שיחה חדשה</div>
                )}
            </div>
        </div>
    );
};



export default Chat;
