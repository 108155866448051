import React, { useEffect,  useState } from 'react'
import apiAxios from "../../redux/apiAxios";

import { listYears } from "../../redux/listMonthH&YearH";
import MagbisHeader from './magbisHeader'
import CardMini from '../cards/cardMini'
import MagbisAccepted from './magbisAccepted'
import MagbisExecution from './magbisExecution'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PiCaretLeft, PiCaretRight } from "react-icons/pi";
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import UserState, { ListState } from '../../models/user-model';
import MagbisMethodsPayment from './methodsPayment/MagbisMethodsPayment';
import MagbisGifts from './Gifts/Gifts';
import { setlistSlice } from '../../redux/listState';
import { TbLogin } from 'react-icons/tb';
import { useForm } from 'react-hook-form';





function MagbisApp() {
    const nav = useNavigate();
    const dispatch = useDispatch();

    const [query]: any = useSearchParams();

    const userSlice: UserState = useSelector((state: any) => state.userSlice);
    const listSlice: ListState = useSelector((state: any) => state.listSlice);

    // const [arList, setArList] = useState<Magbis_State | any>([1]);
    const { register, handleSubmit } = useForm();
    const [selectIdCard, setSelectIdCard] = useState<any>(query.get('idcard'));
    const [selectYear, setSelectYear] = useState<any>(query.get('year'));
    // const baseUrl = process.env.REACT_APP_API_URL
    const headers = { 'x-api-key': userSlice.token }
    const baseUrl: string = process.env.REACT_APP_API_URL!


    // useEffect(() => {
    //     let year = query.get('year');
    //     let card = query.get('idcard');
    //     debugger
    //     console.log(year, card);

    //     if (year) { setSelectIdCard(year); }
    //     if (card) { setSelectYear(card); }

    // }, [])

    useEffect(() => {
        // doApi()
        doANav()
        disRedax()
    }, [])

    const onSubForm = async (_bodyData: any) => {
        setSelectIdCard(_bodyData.idcard);
        setSelectYear(_bodyData.year);
        doANav()
    }
    //אחרי קבלת כרטיס ושנה - מייבא נתונים מהשרת
    const doANav = async () => {
        let year = '';
        let card = '';
        debugger
        if (selectYear) { year = 'year=' + selectYear }
        if (selectIdCard) { card = '&idcard=' + selectIdCard }
        nav(`/magbis?${year}${card}`)
    }


    const disRedax = async () => {
        dispatch(setlistSlice({
            ...listSlice,
            Selectyedidym: (await apiAxios.get(baseUrl + "magbis/listYedidim/" + selectYear, { headers })).data
        }));
    }


    function SelectCardYear() {
        return (
            <form className="" onSubmit={handleSubmit(onSubForm)} >
            <div className='row navbar navbar-expand-lg justify-content-start m-4'>
                {listSlice.select_ansh && listSlice.select_ansh.length ?

                <div className='col-4' >
                    <input {...register('idcard', { required: true })} defaultValue={selectIdCard} type='search' className='form-select ps-4 py-2' list="datalistOptions" id="exampleDataList" placeholder='' onBlur={() => { }} />
                    <datalist id="datalistOptions">
                    {listSlice.select_ansh.map((item, key) => (
                        <option key={key} value={item.idcards} > {item.nameSelect}</option>
                    ))}
                    </datalist>
                </div>
                : ""}
                {/* <div className="btn-group col-6 border" role="group" aria-label="Basic example">
                <button onClick={() => { setSelectYear(Number(selectYear) - 1) }} className="btn col" > <PiCaretRight size={25} /> </button>
                <select {...register('year', { required: true })} value={selectYear} className='btn form-select ps-4' onChange={(e) => setSelectYear(e.target.value)}>
                    <option disabled selected >תבחר שנה בבקשה</option>
                    {listYears.map((item, _key) => (
                    <option value={item.id} > {item.heb_num}</option>
                    ))}
                </select>
                <button onClick={() => { setSelectYear(Number(selectYear) + 1) }} className="btn col" > <PiCaretLeft size={25} /> </button>
                </div> */}


                <div className="btn-group col-6 border " role="group" aria-label="Basic example">
                        <button onClick={() => { setSelectYear(selectYear - 1) }} className="btn col" > <PiCaretRight size={25} /> </button>
                        <select onChange={(e) => { setSelectYear(e.target.value) }} value={selectYear} className='btn form-select flex-fill ps-3 col' >
                            <option disabled selected >תבחר שנה בבקשה</option>
                            {listYears.map((item, key) => (
                                <option value={item.id} > {item.heb_num}</option>
                                ))}
                        </select>
                                <button onClick={() => { setSelectYear(selectYear + 1) }} className="btn col" > <PiCaretLeft size={25} /> </button>
                    </div>

                <button type='submit' className='btn border col-2 me-3'><TbLogin size={25} /></button>
            </div>
            </form>
        )
    }



    return (

        <>
            <div className='sticky-top bg-body text-center rounded '>
                <h1 className='display-1'>חשבון מגבית</h1>
            </div>

            <div className=' d-flex flex-wrap'>
                {selectYear > 780 && selectYear < 999 && selectIdCard > 1 ?
                    <>
                        <div className='col-4  '>

                            <SelectCardYear />

                            <div className=' m-4 ' >
                                <CardMini idCard={selectIdCard} headers={headers} />
                            </div>


                            <div className=' p-4 '>
                                <MagbisHeader idCard={selectIdCard} year={selectYear} headers={headers} />
                            </div>


                            <div className=' border rounded m-4 p-4 '>
                                <MagbisMethodsPayment idCard={selectIdCard} headers={headers} />
                            </div>


                            <div className='border rounded m-4 p-4'>
                                <MagbisGifts idCard={selectIdCard} year={selectYear} headers={headers} userSlice={userSlice} />
                            </div>

                        </div>
                        <div className='col-8'>
                            <div className='border rounded m-4 p-4'>
                                <MagbisAccepted idCard={selectIdCard} year={selectYear} headers={headers} />
                            </div>


                            <div className='border rounded m-4 p-4'>
                                <MagbisExecution idCard={selectIdCard} year={selectYear} headers={headers} />
                            </div>

                        </div>
                    </>
                    : <h1 className='lead text-end-0 ' >
                        <SelectCardYear />
                        <span role="status">ממתין לקבלת פרטי תורם ושנת מגבית   </span>
                        <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                    </h1>
                }

            </div>
        </>
    )
}

export default MagbisApp
