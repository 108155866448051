import * as iconIo from "react-icons/io5";
// import * as iconFa from  "react-icons/fa6";
import * as iconTfi from "react-icons/tfi";
import * as iconCi from "react-icons/ci";
import * as iconSi from "react-icons/si";
import * as iconLia from "react-icons/lia";
import * as iconHi from  "react-icons/hi";

export const links = [
    {
        id: 1,
        text: "בית",
        icon: <iconIo.IoHomeOutline />,
        path: '/home',
    }, {
        id: 2,
        text: "מגבית",
        icon: <iconLia.LiaDonateSolid />,
        path: 'menu',
        subNav: [
            {
                id: 2,
                text: "מגבית פירוט",
                icon: <iconLia.LiaDonateSolid />,
                path: '/magbis',
            }, {
                id: 5,
                text: "רשימת אנש",
                icon: <iconCi.CiViewList />,
                path: '/ansh',
            }, {
                id: 6,
                text: "רשימה מגבית",
                icon: <iconCi.CiViewList />,
                path: '/ListMagbis',
            }
        ]
    }, {
        id: 3,
        text: "שכר לימוד",
        icon: <iconSi.SiSemanticscholar />,
        path: '/home',
    }, {
        id: 4,
        text: "משכורת",
        icon: <iconTfi.TfiBookmarkAlt />,
        path: '/home',
    }, {
        id: 7,
        text: "משימות",
        icon: <iconTfi.TfiNotepad />,
        path: '/tasks',
    }, {
        id: 8,
        text: "הגדרות",
        icon: <iconIo.IoSettingsOutline />,
        path: '/sds',
    },{
        id: 9,
        text: "צ'אט",
        icon: <iconHi.HiOutlineChatAlt2 />,
        path: '/chat',
    }
]


export const linksYedidim = [
    {
        id: 1,
        text: "בית",
        icon: <iconIo.IoHomeOutline />,
        path: '/home',
    }, {
        id: 2,
        text: "פירוט מגבית",
        icon: <iconLia.LiaDonateSolid />,
        path: '/magbis',
    }, {
        id: 6,
        text: "רשימה מגבית",
        icon: <iconCi.CiViewList />,
        path: '/ListMagbis',
    },{
        id: 9,
        text: "שו''ת",
        icon: <iconHi.HiOutlineChatAlt2 />,
        path: '/chat',
    }
]