import React from 'react'
import AppWeather from './appWeather'

export default function Home() {
  return (
    <div className='container'>
        <div className='flex-column col-4 border m-3 rounded-3 shadow'>

        <AppWeather/>
        </div>
    </div>
  )
}
