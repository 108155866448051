// cookiesUtils.tsx
import Cookies from 'js-cookie';
import { now } from 'lodash';

// הגרסה הנוכחית של האפליקציה
const currentVersion = '24.09.24';

// פונקציה לקבלת קוקי מסוים
const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

// פונקציה להגדרת קוקי עם תוקף בימים
const setCookie = (name: string, value: string, days?: number) => {
  Cookies.set(name, value, { expires: days });
};

// פונקציה למחיקת כל הקוקיז
const clearCookies = () => {
  Object.keys(Cookies.get()).forEach(cookieName => {
    Cookies.remove(cookieName);
  });
};

// פונקציה לניהול גרסה
export const handleVersion = () => {
  const savedVersion = getCookie('appVersion');

  if (savedVersion !== currentVersion) {
    console.log('Loaded new version!');
    setCookie('appVersion', currentVersion, 30); // שמור את הגרסה החדשה ל-30 יום

    // ניקוי כל הקוקיז במקרה של גרסה חדשה
    clearCookies();

    // תוכל להוסיף כאן לוגיקה נוספת אם נדרשת פעולה כלשהי עם טעינת גרסה חדשה
  }
};
