import React, { useState } from 'react';

const EditFormComponent: React.FC = () => {
    const [formData, setFormData] = useState({
        idcard: '',
        codMusad: '',
        idTransmissionKeys: '',
        NameAccount: '',
        BankTax: '',
        Branch: '',
        TaxAccount: '',
        Active: '',
        SentToBank: '',
        ApprovedBank: '',
        Paymentmethod: '',
        DayPayment: '',
        MisCard: '',
        Tokef: '',
        CVV: '',
        T_Z: '',
        AddressReceipt: '',
        Matbea: '',
        nots: '',
        LimitedAmount: '',
        ActiveToDate: '',
        Discount: '',
        CMDReceipt: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Add form submission logic here
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>Id Card:</label>
            <input type="text" name="idcard" value={formData.idcard} onChange={handleChange} />

            <label>Cod Musad:</label>
            <input type="text" name="codMusad" value={formData.codMusad} onChange={handleChange} />

            {/* Include similar input fields for the other form fields */}

            <button type="submit">Submit</button>
        </form>
    );
};

export default EditFormComponent;
