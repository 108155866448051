import GiftAddEdit from './giftAddEdit';
import { Gifts_State } from '../magbis_model';
import { FaRegEdit } from "react-icons/fa";
import { TbPointFilled } from "react-icons/tb";
import { RiChatNewLine } from "react-icons/ri";
import { useEffect, useState } from 'react';
import apiAxios from "../../../redux/apiAxios";

export default function MagbisGifts(props: any) {
    const { idCard, year, headers, userSlice } = props
    const [arList, setArList] = useState<Gifts_State[]>([]);
    // const idCard: number = props.idCard
    // const year: number = props.year
    // const headers: any = props.headers


    useEffect(() => {
        doApi()
    }, [idCard, year])

    //ייבוא פרטים לפי כרטיס ושנה
    const doApi = async () => {
setArList([]);
        if (idCard === undefined || year === undefined) { return [] }
        try {
            const url: string = process.env.REACT_APP_API_URL! + "magbis/gifts/all/" + idCard + "/" + year + "/";
            const data: Gifts_State[] = (await apiAxios.get(url, { headers })).data
            // console.log(await data);
            setArList(await data);
            console.log(arList);


        } catch (error: any) {
            if (error.response.status === 401) {

            }
        }
    }




    return (

        <div className='text-center'>
            <div className='d-flex '>
                <h3>מתנות שקיבל</h3> {userSlice.role === 2 && <GiftAddEdit item={{ Seats: year, idcard: idCard }} doApi={doApi} icon={<RiChatNewLine />} headers={headers} />}
            </div>
            <div>
                {arList.map((item) => {

                    return (
                        <div className='h5 text-end' title={item.Notes}>
                            {userSlice.role === 2 ?
                                <GiftAddEdit item={item} doApi={doApi} icon={<TbPointFilled />} headers={headers} />
                                : <TbPointFilled />}
                            {item.Gift}
                        </div>
                    )
                })}
            </div>
        </div>

    )
}
