import { useSelector } from "react-redux"
import './cardMini.css'
import UserState from "../../models/user-model";
import { Card_Lite } from "../../models/card_model";
import { useEffect, useState } from "react";
import apiAxios from "../../redux/apiAxios";

export default function CardMini(props: any) {
    const { idCard,headers} = props
    const [arList, setArList] = useState<any>([]);

    useEffect(() => {
        doApi()
    }, [idCard])

    //ייבוא פרטים לפי כרטיס ושנה
    const doApi = async () => {

        if (idCard === undefined ) { return [] }
        try {
            const url: string = process.env.REACT_APP_API_URL! + "cards/mini/" + idCard
            const data: Card_Lite = (await apiAxios.get(url, { headers })).data
            // console.log(await data);
            setArList(await data);
            console.log(arList);

        } catch (error: any) {
            if (error.response.status === 401) {

            }
        }
    }



    const C2clink = async (tel: string) => {
        const url = process.env.REACT_APP_API_URL! + `tools/c2clink/coll`
        const data = (await apiAxios.post(url, { tel }, { headers})).data
    }



    return (
        <div >

            <div className='row flex-wrap justify-content-around'>
                <label className="text-center h2" >{arList.FirstName + " " + arList.FamilyName}</label>
                <label className="text-center m-1" >{arList.Address + " " + arList.Building + (arList.Home ? "/" + arList.Home : "") + " - " + arList.City}</label>
                <div className="d-lg-flex justify-content-center">
                    {arList.Telephone && <span> <span className="TavitTel" >טלפון</span> <span onClick={() => C2clink(arList.Telephone)} > {arList.Telephone}</span> </span>}
                    {arList.Mobile1 && <span> <span className="TavitTel" >בעל</span> <span onClick={() => C2clink(arList.Mobile1)} > {arList.Mobile1}</span> </span>}
                    {arList.Mobile2 && <span> <span className="TavitTel" >אשה</span> <span onClick={() => C2clink(arList.Mobile2)} > {arList.Mobile2}</span> </span>}
                    {arList.MobileHome && <span> <span className="TavitTel" >בית</span> <span onClick={() => C2clink(arList.MobileHome)} > {arList.MobileHome}</span> </span>}
                </div>

            </div>
        </div>

    )
}

