import React, { useState } from "react"
import UserState from "../../models/user-model";
import { useDispatch } from "react-redux";
import { setUserDetils } from "../../redux/userState";
import './login.css'
import { useNavigate } from "react-router-dom";
import { setlistSlice } from "../../redux/listState";
import { Image } from "react-bootstrap";
import { FaRegUser } from "react-icons/fa";
import { useForm } from "react-hook-form";
import apiAxios from "../../redux/apiAxios";
const baseUrl: string = process.env.REACT_APP_API_URL!


export default function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const [errState, setErrState] = useState<string>();
  const [formNow, setFormNow] = useState(1);
  const [myPhone, setMyPhone] = useState<string>();
  const [countdown, setCountdown] = useState(180); // 180 seconds = 3 minutes
  const nav = useNavigate();

  const doApi = async (event: any) => {
    event.preventDefault()
    const user = event.target[0].value;
    const pass = event.target[1].value;
    try {
      const res = await apiAxios.put<UserState>(baseUrl + 'users', { user, pass })
      if (res.data.IdUser) {
        await loginNow(res.data)

      } else {
        setErrState('הנתונים שהוקשו שגויים - נסה שוב')
      }
    } catch (error) {
      // console.log('error', error);
      setErrState('אירעה שגיאה - נסה שוב')
    }
  }

  //בלחיצה על buutn מופעל פונקציה זו
  const onSubForm = async (_bodyData: any) => {
    //עדכון
    const url = process.env.REACT_APP_API_URL! + `users/phone`
    debugger
    if (myPhone) {
      try {
        const data = (await (apiAxios.post(url, { ..._bodyData }))).data
        await loginNow(data)
      } catch (error) {
        setErrState('אירעה שגיאה - נסה שוב')
      }
    } else {
      try {
        await (apiAxios.put(url, { ..._bodyData }))
        setMyPhone(_bodyData.phone)

        const countdownInterval = setInterval(() => {
          setCountdown(prevCountdown => {
            if (prevCountdown > 0) {
              return prevCountdown - 1;
            } else {
              setMyPhone(''); // Reset myPhone state variable
              clearInterval(countdownInterval); // Stop the countdown
              return 0;
            }
          });
        }, 1000); // Update countdown every second

        return () => clearInterval(countdownInterval);
      } catch (error) {
        setErrState('אירעה שגיאה - נסה שוב')
      }
    }

  }

  const loginNow = async (r: any) => {
    dispatch(setUserDetils(r));
    const data = (await apiAxios.get(baseUrl + "system/selectFind/", { headers: { 'x-api-key': r.token } })).data

    dispatch(setlistSlice(data));
    // dispatch(setlistSlice({
    //   select_TaskTyp: (await axios.get(baseUrl + "system/selectFind/SubTaskTyp/", { headers: { 'x-api-key': r.token } })).data,
    //   select_userNames: (await .get(baseUrl + "system/selectFind/usernames/", { headers: { 'x-api-key': r.token } })).data,
    //   select_ansh: (await axios.get(baseUrl + "system/selectFind/ansh/", { headers: { 'x-api-key': r.token } })).data,
    // }));

    nav('/');
  }

  const formatCountdown = (countdown: number) => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      <h1 className='display-1 p-5 ' >IT</h1>
      <div className="d-flex justify-content-evenly login-body">
        <div className="align-items-lg-start" >
          <h3 className='text-center p-2 fs-1' > <FaRegUser /> כניסת משתמש</h3>
          <br />

          {formNow === 1 &&
            <>
              <ul className="nav-underline d-flex">
                <p className="nav-item">
                  <a onClick={() => setFormNow(2)} className="nav-link fs-5" href="#">באמצעות קוד חד פעמי</a>
                </p>
                <p className="nav-item">
                  <a className="nav-link active fs-5" href="#">באמצעות סיסמה</a>
                </p>
              </ul>

              <br />

              <form onSubmit={doApi}>
                <div className='container login-card'  >
                  <div className="form-floating mb-3">
                    <input name="user" type="text" className="form-control" id="floatingInput"  ></input>
                    <label htmlFor="floatingInput">שם משתמש</label>
                  </div>
                  <div className="form-floating">
                    <input name="pass" type="password" className="form-control" id="floatingPassword" ></input>
                    <label htmlFor="floatingPassword">סיסמה</label>
                  </div>
                  <button type="submit" className='btn btn-dark form-select my-3 fs-4 '>כניסה

                  </button>
                  <p className='text-bg-danger rounded text-center ' >{errState}</p>
                </div>
              </form>
            </>
          }


          {formNow === 2 &&
            <>
              <ul className="nav-underline d-flex">
                <p className="nav-item">
                  <a className="nav-link active fs-5" href="#">באמצעות קוד חד פעמי</a>
                </p>
                <p className="nav-item">
                  <a onClick={() => setFormNow(1)} className="nav-link fs-5" href="#">באמצעות סיסמה</a>
                </p>
              </ul>

              <br />
              {myPhone ?
                <form onSubmit={handleSubmit(onSubForm)}>
                  <div className='container login-card'  >
                    <p className="text-center mb-3 fw-bold" >כעת מופיע סיסמה על צג הטלפון שלך, </p>
                    <p className="text-center mb-3 fw-bold" > אין צורך לענות לשיחה, </p>
                    <p className="text-center mb-3 fw-bold" >הסיסמה יפוג בעוד {formatCountdown(countdown)} דקות </p>
                    <div className="form-floating mb-3">
                      <input {...register("pass", { required: true })} type="text" className="form-control" id="floatingInput"  ></input>
                      <label htmlFor="floatingInput">הכנס המספר שמופיע על צג הטלפון</label>
                    </div>

                    <button type="submit" className='btn btn-dark form-select my-3 fs-4 '>כניסה

                    </button>
                    <p className='text-bg-danger rounded text-center ' >{errState}</p>
                  </div>
                </form>
                :
                <form onSubmit={handleSubmit(onSubForm)}>
                  <div className='container login-card'  >
                    <div className="form-floating mb-3">
                      <input {...register("phone", { required: true })} type="tel" className="form-control" id="floatingInput"  ></input>
                      <label htmlFor="floatingInput">הכנס מספר הטלפון שלך</label>
                    </div>

                    <button type="submit" className='btn btn-dark form-select my-3 fs-4 '>שלח לי קוד

                    </button>
                    <p className='text-bg-danger rounded text-center ' >{errState}</p>
                  </div>
                </form>
              }
            </>
          }
        </div>
        <div className="d-flex align-items-center" >
          <Image className="bnyan1" src={require('../../img/bnyan1.jpg')} style={{ width: "600px" }} />
        </div>
      </div>
    </div>

  )
}