import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BsEnvelopeCheckFill, BsEnvelopeOpen, BsFillEnvelopeFill, BsToggleOff, BsToggleOn, BsToggles } from "react-icons/bs";
import { RiUserSharedLine } from "react-icons/ri";
import { GrUserExpert } from "react-icons/gr";
import dayjs from 'dayjs';
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import UserState, { ListState } from '../../models/user-model';





export default function TasksFilter(props:any) {
    const userSlice: UserState = useSelector((state: any) => state.userSlice);
    const listSlice: ListState = useSelector((state: any) => state.listSlice);


    const [query] = useSearchParams();
    const nav = useNavigate();

    const FilterStart = [
        { key: 'done', role: 1, link: 'done=0', Filter: 'done=0', icon: <BsToggleOff size={34} />, title: 'מציג רק משימות שלא בוצעו' },
        { key: 'newMessage', role: 0, link: '', Filter: '', icon: <BsEnvelopeCheckFill size={34} />, title: 'מציג כל המשימות' },
        { key: 'dateToDo', role: dayjs().format("YYYY-MM-DD"), link: '&dateToDo=' + dayjs().format("YYYY-MM-DD"), Filter: "dateToDo<='" + dayjs().format("YYYY-MM-DD") +"'" },
        { key: 'ForIDuser', role: userSlice.IdUser, link: '&ForIDuser=' + userSlice.IdUser, Filter: 'ForIDuser=' + userSlice.IdUser },
        { key: 'IdCard', role: '', link: '', Filter: '' },
        { key: 'UserChange', role: '', link: '', Filter: '' },
        { key: 'SubTaskType', role: '', link: '', Filter: '' }
    ]

    const [filterUse, setFilterUse] = useState(FilterStart)
    const [pageLoading, setpageLoading] = useState(false)



    //עדכון מערך
    const upfilterUse:any = (key:any, role:any) => {
        const yourNextList = [...filterUse];
        const CF:any = yourNextList.find(
            a => a.key === key
        );
        switch (key) {
            case 'done':

                switch (CF.role) {
                    case 0:
                        CF.role = 1;
                        CF.link = 'done=1';
                        CF.Filter = 'done<>0';
                        CF.icon = <BsToggleOn size={34} />;
                        CF.title = 'מציג רק משימות שבוצעו';
                        break
                    case 1:
                        CF.role = 2;
                        CF.link = '';
                        CF.Filter = '';
                        CF.icon = <BsToggles size={34} />;
                        CF.title = 'מציג כל המשימות';
                        break
                    case 2:
                        CF.role = 0;
                        CF.link = 'done=0';
                        CF.Filter = 'done=0';
                        CF.icon = <BsToggleOff size={34} />;
                        CF.title = 'מציג רק משימות שלא בוצעו';
                        break
                }
                break
            case 'newMessage':
                switch (CF.role) {
                    case 0:
                        CF.role = 1;
                        CF.link = '&newMessage=1';
                        CF.Filter = 'newMessage<>0';
                        CF.icon = <BsFillEnvelopeFill size={34} />;
                        CF.title = 'מציג רק משימות שנקראו';
                        break
                    case 1:
                        CF.role = 2;
                        CF.link = '';
                        CF.Filter = '';
                        CF.icon = <BsEnvelopeCheckFill size={34} />;
                        CF.title = 'מציג כל המשימות';
                        break
                    case 2:
                        CF.role = 0;
                        CF.link = '&newMessage=0';
                        CF.Filter = 'newMessage=0';
                        CF.icon = <BsEnvelopeOpen size={34} />;
                        CF.title = 'מציג רק משימות שלא נקראו';
                        break
                    default:
                }
                break
            case 'dateToDo':
                let date = dayjs(role).format("YYYY-MM-DD")
                CF.role = date;
                CF.link = '&dateToDo=' + date;
                CF.Filter = "dateToDo<='" + date + "'"
                break
            default:
                if (role) {
                    CF.role = role;
                    CF.link = `&${key}=${role}`;
                    CF.Filter = `${key}='${role}'`;
                } else {
                    CF.role = '';
                    CF.link = '';
                    CF.Filter = '';
                }
        }
        setFilterUse(yourNextList);
    }

    function clicdateToDo01(e:any) {
        const d = new Date(filterUse[2].role);
        d.setDate(d.getDate() + e)
        upfilterUse('dateToDo', d)
    }



    // עדכן מערך בפתיחה מ URL
    useEffect(() => {
        FilterStart.map(x => {
            query.get(x.key) && upfilterUse(x.key, query.get(x.key));
        })
        doLoading()
        setpageLoading(true);
    }, [])

    //עדכון URL ובקשה מחדש - לאחר עדכון מערך
    useEffect(() => {
        if (pageLoading) {
            doLoading()
        }
    }, [filterUse])

    const doLoading = () => {
        let Link = ''
        filterUse.filter(y => (y.link)).map(x => {
            Link = Link + x.link;
        })
        nav(`/tasks?${Link}`)

        let filter = '';
        filterUse.filter(y => (y.Filter)).map(x => {
            filter = (filter && filter + ' and ') + x.Filter;
        })
        // console.log(filter)

        props.doApi(filter)
    }

    return (
        <div className=' p-2 border mb-2 rounded d-flex align-items-stretch bg-bg-body' >


            <button onClick={() => { upfilterUse('done', '') }} className='btn' title={filterUse[0].title} >
                {filterUse[0].icon}
            </button>

            <button onClick={() => { upfilterUse('newMessage', '') }} className='btn' title={filterUse[1].title} >
                {filterUse[1].icon}
            </button>



            <div className='border btn mx-2'>
                <button onClick={() => { clicdateToDo01(- 1) }} className="btn" > <PiCaretRight size={25} /> </button>
                <input onInput={(e:any) => { upfilterUse('dateToDo', e.target.value) }} value={filterUse[2].role} type='date' className='btn'>
                </input>
                <button onClick={() => { clicdateToDo01(+ 1) }} className="btn" > <PiCaretLeft size={25} /> </button>
            </div>

            {/* משימה עבור */}
            <select onInput={(e:any) => { upfilterUse('SubTaskType', e.target.value) }} value={filterUse[6].role} className=' border btn mx-2 ' >
                <option value=''>סוג משימה</option>
                {listSlice.select_TaskTyp.map((item, key) => (
                    <option value={item.SubTaskType} > {item.SubTaskType}</option>
                ))}
            </select>

            <div className=' border btn mx-2 p-0'>
                <p className='btn p-0 pe-2'><GrUserExpert size={25} /></p>
                <select onInput={(e:any) => { upfilterUse('ForIDuser', e.target.value) }} value={filterUse[3].role} className=' btn p-0' >
                    <option value=''>עבור משתמש</option>
                    {listSlice.select_userNames.map((item, key) => (
                        <option value={item.IdUser} > {item.NameUser}</option>
                    ))}
                </select>
            </div>

            <div className=' border btn mx-2 p-0'>
                <p className='btn p-0 pe-2'><RiUserSharedLine size={25} /></p>
                <select onInput={(e:any) => { upfilterUse('UserChange', e.target.value) }} value={filterUse[5].role} className=' btn p-0 ' >
                    <option value=''>מאת משתמש</option>
                    {listSlice.select_userNames.map((item, key) => (
                        <option value={item.IdUser} > {item.NameUser}</option>
                    ))}
                </select>
            </div>

            <div className=' border btn mx-2 p-0'>
                {listSlice.select_ansh[0].idcards &&
                    <div >
                        <input onChange={(e) => { upfilterUse('IdCard=', e.target.value) }} value={filterUse[4].role} type='search' className='btn' list="datalistOptions" id="exampleDataList" placeholder="תבחר שם תורם ..." />
                        <datalist id="datalistOptions">
                            {listSlice.select_ansh.map((item, key) => (
                                <option value={item.idcards} > {item.nameSelect}</option>
                            ))}
                        </datalist>
                    </div>
                }
            </div>

        </div>
    )
}
